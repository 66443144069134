var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-page-form__block"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitSendCode()}}},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.old_password) || _vm.errorMessage.old_password,
      },attrs:{"label":"Старый пароль","placeholder":"••••","type":"password","field":_vm.old_password,"autocomplete":"current-password","error":(!_vm.$v.old_password.required && _vm.$v.old_password.$error
          ? 'Обязательное поле'
          : !_vm.$v.old_password.minLength && _vm.$v.old_password.$error
          ? 'Минимум ' + _vm.$v.old_password.$params.minLength.min + ' символов'
          : '') || _vm.errorMessage.old_password},on:{"input":function($event){_vm.old_password = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.new_password) || _vm.errorMessage.new_password,
      },attrs:{"label":"Новый пароль","placeholder":"••••","type":"password","field":_vm.new_password,"autocomplete":"new-password","error":(!_vm.$v.new_password.required && _vm.$v.new_password.$error
          ? 'Обязательное поле'
          : !_vm.$v.new_password.minLength && _vm.$v.new_password.$error
          ? 'Минимум ' + _vm.$v.new_password.$params.minLength.min + ' символов'
          : '') || _vm.errorMessage.new_password},on:{"input":function($event){_vm.new_password = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.confirm_new_password) ||
          this.errorMessage.confirm_new_password,
      },attrs:{"label":"Повторите пароль","placeholder":"••••","type":"password","field":_vm.confirm_new_password,"autocomplete":"new-password","error":(!_vm.$v.confirm_new_password.required && _vm.$v.confirm_new_password.$error
          ? 'Обязательное поле'
          : !_vm.$v.confirm_new_password.minLength &&
            _vm.$v.confirm_new_password.$error
          ? 'Минимум ' +
            _vm.$v.confirm_new_password.$params.minLength.min +
            ' символов'
          : '') || _vm.errorMessage.confirm_new_password},on:{"input":function($event){_vm.confirm_new_password = $event}}}),_c('div',{staticClass:"modal__footer"},[_c('button',{staticClass:"btn btn--primary w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"submit"}},[_vm._v(" отправить ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }