<template>
  <Modal
    title="введите данные для&nbsp;доставки подарков"
    name="delivery"
    size="md"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <DeliveryForm />
    </template>
  </Modal>
</template>

<script>
import DeliveryForm from "../form/DeliveryForm.vue";
import Modal from "@/components/Modal.vue";

export default {
  props: [],
  components: {
    Modal,
    DeliveryForm,
  },
  data: function () {
    return {};
  },

  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>

<style lang="scss" scoped>
.modal {
  align-self: flex-start;
}
</style>

<style lang="scss"></style>
