<template>
  <section class="winners section" id="winners">
    <div class="container">
      <div class="winners__header">
        <h2 class="winners__title section-title">
          <span>Победители</span>
        </h2>
      </div>

      <div class="winners__top">
        <div class="winners__search">
          <form
            class="winners__search-form"
            v-on:submit.prevent="searchForm"
            :class="{
              error:
                validationStatus($v.searchQuery) ||
                this.errorMessage.searchQuery,
            }"
          >
            <input
              class="winners__search-field"
              type="email"
              name="term"
              v-model="searchQuery"
              @focus="errorReset()"
              placeholder="Поиск по e-mail"
            />
            <button type="submit" class="winners__search-button"></button>
            <div
              v-if="searchQuery"
              @click="formReset()"
              class="winners__search-clear"
            ></div>
            <div
              class="error-hint"
              v-if="
                validationStatus($v.searchQuery) ||
                this.errorMessage.searchQuery
              "
              v-html="
                (!$v.searchQuery.required && $v.searchQuery.$error
                  ? 'Обязательное поле'
                  : !$v.searchQuery.email && $v.searchQuery.$error
                  ? 'Некорректный e-mail'
                  : '') || this.errorMessage.searchQuery
              "
            ></div>
          </form>
        </div>
        <div class="winners-select">
          <Select
            :options="[
              { id: 0, label: 'Все призы' },
              { id: 1, label: 'Сертификат на покупку в интернет-магазине RED' },
              { id: 2, label: 'Чайник RED COLORSENSE AM120D' },
              { id: 3, label: 'Минипекарня RED solution RMB-M607' },
              { id: 4, label: 'Главный приз' },
            ]"
            class="_green"
            :field="{ id: 0, label: 'Все призы' }"
            @input="prize = $event"
          />
        </div>
      </div>

      <div class="winners__content">
        <div class="winners__result">
          <Table :thead="fields" v-if="winners && winners.length">
            <template v-slot:tbody>
              <tr v-for="(item, index) in winners" :key="index">
                <td style="width: 20%">
                  {{ item.name || "-" }}
                </td>
                <td style="width: 40%">
                  {{ item.email || "-" }}
                </td>
                <td style="width: 40%">
                  {{ item.prize || "-" }}
                </td>
              </tr>
            </template>
            <template v-slot:mobile>
              <table class="winners-tab-mobile">
                <tr v-for="(item, index) in winners" :key="index">
                  <td>
                    <table class="table-mobile__inner">
                      <tr>
                        <td class="gradient-text">Имя</td>
                        <td>
                          {{ item.name || "-" }}
                        </td>
                      </tr>
                      <tr>
                        <td class="gradient-text">E-mail</td>
                        <td>
                          {{ item.email || "-" }}
                        </td>
                      </tr>
                      <tr>
                        <td class="gradient-text">Приз</td>
                        <td>{{ item.prize || "-" }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </template>
          </Table>
          <div class="no-results" v-if="!winners?.length">
            <div class="no-results" v-if="noSearchResults">
              Среди победителей данный аккаунт не найден
            </div>
            <div v-else-if="activeItem === 3">
              Победитель проверяется и&nbsp;скоро будет опубликован
            </div>
            <div v-else>Розыгрыш еще не&nbsp;проводился</div>
          </div>
        </div>
      </div>
      <ul class="pagination" v-if="pages.length > 1">
        <li
          class="pagination-item"
          v-if="page != 1"
          @click="page--, paginate()"
        >
          <svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6758 18.9688L1.63 9.92297L10.6758 0.877182"
              stroke="#E4C9B5"
              stroke-width="2.01017"
            />
          </svg>
        </li>
        <li
          class="pagination-item"
          v-for="pageNumber in pages.length"
          :key="pageNumber"
          @click="(page = pageNumber), paginate()"
          :class="{
            current: page === pageNumber,
            last: pageNumber == pages.length && Math.abs(pageNumber - page) > 3,
            first: pageNumber == 1 && Math.abs(pageNumber - page) > 3,
            visible:
              Math.abs(pageNumber - page) < 3 ||
              pageNumber === pages.length ||
              pageNumber == 1,
          }"
        >
          {{ pageNumber }}
        </li>
        <li
          @click="page++, paginate()"
          v-if="page < pages.length"
          class="pagination-item"
        >
          <svg
            width="13"
            height="20"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.62793 0.873596L10.6737 9.91938L1.62793 18.9652"
              stroke="#E4C9B5"
              stroke-width="2.01017"
            />
          </svg>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Table from "@/components/Table.vue";
import { required } from "vuelidate/lib/validators";
import Select from "@/components/form/inputSelect.vue";

export default {
  components: {
    Table,
    Select,
  },
  data: function () {
    return {
      tabs: [
        {
          label: "ежедневные призы",
          value: 1,
        },
        {
          label: "еженедельные призы",
          value: 2,
        },
        {
          label: "главный приз",
          value: 3,
        },
      ],
      page: 1,
      perPage: 1,
      pages: [],
      prize: null,
      currentWeek: "03.10.2022-09.10.2022",
      searchQuery: null,
      date: null,
      total: 0,
      noResults: false,
      noSearchResults: false,
      errorMessage: {
        date: null,
        searchQuery: null,
      },
      fields: [
        {
          text: "Имя",
          size: "20%",
        },
        {
          text: "E-mail",
          size: "40%",
        },
        {
          text: "Приз",
          size: "40%",
        },
      ],
      winners: [],
      showPaginate: true,
      activeItem: 1,
    };
  },
  validations: {
    searchQuery: { required },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        searchQuery: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setPages(total) {
      this.pages = [];
      if (total) {
        for (let index = 1; index <= total; index++) {
          this.pages.push(index);
        }
      }
    },
    paginate() {
      let page = this.page;
      this.$store.dispatch("GetWinners", { page: page }).then((response) => {
        this.winners = response.data.rows;
      });
    },
    getWinnersResult(val) {
      this.noSearchResults = false;
      this.$store
        .dispatch("GetWinners", { page: this.page })
        .then((response) => {
          this.winners = response.data.rows;
          if (this.winners?.length < 1) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        });
    },
    searchForm() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.page = 1;
      const o = {
        email: this.searchQuery,
        page: 1,
      };
      this.$store.dispatch("GetWinners", o).then((response) => {
        this.winners = response.data.rows;
        this.total = response.data.paging.total;
        if (!this.winners.length) {
          this.noSearchResults = true;
          this.$v.$reset();
          this.page = 1;
        } else {
          this.noSearchResults = false;
        }
      });
    },
    formReset() {
      this.$v.$reset();
      this.page = 1;
      this.searchQuery = null;
      this.noSearchResults = false;
      this.$store
        .dispatch("GetWinners", {
          page: this.page,
        })
        .then((response) => {
          this.winners = response.data.rows;
        });
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
  mounted() {},
  created() {
    try {
      this.$store.dispatch("GetWinners").then((response) => {
        this.total = response.data.paging.total;
        this.winners = response.data.rows;
        if (!this.winners?.length) {
          this.noResults = true;
        } else {
          this.noResults = false;
          this.setPages(this.total);
        }
      });
    } catch (e) {
      console.log(e);
      this.noResults = true;
    }
  },
  watch: {
    prize() {
      this.page = 1;
      const o = {
        prize: this.prize,
        page: 1,
      };
      this.$store.dispatch("GetWinners", o).then((response) => {
        this.total = response.data.paging.total;
        this.winners = response.data.rows;
        if (!this.winners.length) {
          this.noSearchResults = true;
          this.$v.$reset();
          this.page = 1;
          this.searchQuery = null;
        } else {
          this.noSearchResults = false;
        }
      });
    },
    winners() {
      this.setPages(this.total);
    },
  },
  computed: {
    // displayedWinners() {
    //   return this.paginate(this.winners);
    // },
  },
};
</script>

<style lang="scss">
.winners {
  position: relative;
  display: flex;
  padding-top: rem(73px);
  padding-bottom: rem(0px);
  align-items: center;
  z-index: 1;
  background-size: cover;
  &::before {
    content: "";
    position: absolute;
    top: 10%;
    left: 20%;
    transform: translate(-50%, -50%);
    display: block;
    @include size(rem(1000px));
    background: url("../assets/images/shadow.svg") no-repeat center;
    background-size: contain;
    pointer-events: none;
    z-index: -10;
  }
  &::after {
    content: "";
    position: absolute;
    top: 80%;
    left: 100%;
    transform: translate(-50%, -50%);
    display: block;
    @include size(rem(1080px));
    background: url("../assets/images/shadow.svg") no-repeat center;
    background-size: contain;
    pointer-events: none;
    z-index: -10;
  }
  &__title {
    margin-bottom: rem(30px);
  }
  &__top {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  &__tabs {
    display: flex;
    flex-direction: column-reverse;
    &-nav {
      position: relative;
      height: rem(54px);
      padding: 0 rem(25px);
      margin-bottom: rem(11px);
      background: #ffb59d;
      color: rgba($red, 0.5);
      text-transform: uppercase;
      line-height: rem(54px);
      text-align: center;
      font-family: $medium;
      font-size: rem(14px);
      border-radius: rem(25px);
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 100%;
        height: 100%;
        transform: translateX(-50%);
        background: $pink;
        border-radius: 25px;
        opacity: 0;
        z-index: -1;
      }
      &._active {
        background: $pink;
        color: $red;
        &::before {
          opacity: 1;
        }
      }
    }
  }
  &__select {
    margin: 0;
    width: 100%;
  }
  &__header {
    display: flex;
    justify-content: center;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  &__search-field {
    width: 100%;
    padding: 0px rem(40px) 0 rem(20px);
    height: rem(48px);
    font-size: $field-font-size-sm;
    background: $green;
    border: 1px solid;
    font-family: $light;
    box-shadow: none;
    text-align: left;
    color: #fff;
    outline: none;
    @include gradient-bg($green, $field-border-radius-sm);
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  &__search {
    width: 100%;
  }
  &__search-form {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: rem(0px) auto rem(15px);
  }
  &__search-button {
    position: absolute;
    top: 50%;
    right: rem(15px);
    transform: translateY(-50%);
    width: rem(43px);
    height: rem(43px);
    z-index: 1;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../assets/images/icons/search.svg") no-repeat center;
      background-size: rem(20px);
    }
  }
  &__search-clear {
    position: absolute;
    top: 0;
    right: rem(65px);
    width: rem(18px);
    height: 100%;
    background: url("../assets/images/icons/close-white.svg") no-repeat center;
    background-size: 100%;
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__result {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: auto;
    height: 100%;
    font-family: $bold;
    font-size: rem(18px);
  }
  &__img {
    position: absolute;
    left: 0;
    bottom: rem(-120px);
    pointer-events: none;
    z-index: 10;
  }
  .no-results {
    width: 100%;
    border-radius: rem(20px);
    align-self: center;
    padding: rem(90px) 0;
    text-align: center;
  }

  @media (orientation: landscape) {
    padding-bottom: 0;
    padding-top: rem(180px);
    &::before {
      top: 30%;
      left: 0%;
      @include size(rem(2500px));
    }
    &::after {
      top: 30%;
      left: 100%;
      @include size(rem(1080px));
    }
    .container {
      width: rem(1260px);
      padding-bottom: 0 !important;
    }
    &__content {
      position: relative;
      padding: rem(40px) rem(80px) rem(50px) rem(80px);
      background: $green;
      border-radius: rem(25px);
    }
    &__header {
      margin-bottom: rem(91px);
    }
    &__top {
      width: 84%;
      gap: 0 rem(29px);
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 auto rem(80px);
      padding-top: 0;
      .form-select .v-select {
        margin-bottom: 0;
      }
    }
    &__title {
      margin-bottom: rem(35px) !important;
    }
    &__search-field {
      width: 100%;
      height: rem(70px);
      padding: 2px rem(25px) 0;
      font-size: rem(16px);
      @include gradient-bg($green, $field-border-radius-lg);
    }
    &__result {
      min-height: rem(300px);
    }
    &__tabs {
      flex: auto;
      flex-direction: row;
      &-nav {
        width: rem(232px);
        flex-grow: 1;
        height: rem(54px);
        padding: 0 rem(20px);
        line-height: rem(56px);
        margin-bottom: 0;
        margin-right: rem(12px);
        font-size: rem(14px);
        border-radius: rem(8px);
        &::before {
          top: rem(-2px);
          left: rem(0px);
          width: calc(100% + #{rem(4px)});
          height: rem(90px);
          transform: translateX(0%);
          border-radius: 8px;
          border-bottom-right-radius: rem(0px);
          border-bottom-left-radius: rem(0px);
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    &-select {
      flex: 50%;
    }
    &__search {
      width: auto;
      flex: 50%;
      min-width: rem(372px);
      &-form {
        margin: 0 auto;
      }
      &-clear {
        right: rem(75px);
      }
    }
    &__search-button {
      right: rem(20px);
      &::after {
        background: url("../assets/images/icons/search.svg") no-repeat center;
        background-size: rem(22px);
      }
    }
    &-tab-desktop {
      display: block;
    }
    &-tab-mobile {
      display: none;
    }
    .no-results {
      max-width: 70%;
      margin: auto;
      font-size: rem(28px);
    }
  }
}
</style>
