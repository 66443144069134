<template>
  <section class="hero section" id="home">
    <div class="container">
      <div class="hero-block">
        <div class="hero__subtitle gradient-text">
          01 августа – 30 сентября 2024 г.
        </div>
        <div class="hero__title">СЕЗОН ПРИЗОВ <br />ОТ GREENFIELD</div>
        <div class="hero__buttons">
          <button
            class="btn btn--primary"
            @click="
              toCheckUpload(), $metrika.reachGoal('register_receipt_1_screen')
            "
          >
            <span>зарегистрировать чек</span>
          </button>
          <button @click="openInNewTab()" class="btn btn--bordered">
            <span>перейти в чат-бот</span>
            <img src="../assets/images/icons/tg.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
    <img src="../assets/images/products.png" alt="" class="hero__products" />
  </section>
</template>

<script>
export default {
  components: {
    // Feedback,
  },
  data: () => ({}),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    openInNewTab() {
      // this.$metrika.reachGoal('bot');
      

      // this.doGoalVK('chatbot');
      window.open("https://t.me/promo_greenfield_bot", "_blank").focus();
      this.adhighChatBot();
    },
  },
  mounted() {
    // this.toCheckUpload()
    document.querySelector(".hero").addEventListener("mousemove", parallax);
    function parallax(event) {
      this.querySelectorAll(".js-parallax-wrap > *").forEach((shift) => {
        const position = shift.getAttribute("value");
        const x = (window.innerWidth - event.pageX * position) / 200;

        shift.style.transform = `translateX(${x}px)`;
      });
    }
  },
};
</script>
<style lang="scss">
.hero {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: rem(90px);
  height: calc(var(--vhH, 1vh) * 100);
  flex-direction: column;
  justify-content: flex-start;
  z-index: 11 !important;
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    z-index: 30;
  }

  &__subtitle {
    margin-bottom: rem(5px);
    font-size: rem(16px);
    line-height: 1.3;
    font-family: $medium;
  }
  &__title {
    margin-bottom: rem(30px);
    font-size: rem(29px);
    font-family: $bold;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
  }
  &__suptitle {
    margin-bottom: rem(38px);
    font-size: rem(14px);
    line-height: 1;
    font-family: $medium;
  }
  &__products {
    position: absolute;
    top: 40%;
    left: 0;
    max-width: none;
    height: 95vh;
    align-self: flex-start;
    pointer-events: none;
  }
  &-block {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: rem(16px) 0;
  }
  @media (orientation: landscape) {
    .container {
      position: relative;
      padding-top: vh(130px);
    }
    &__subtitle {
      margin-bottom: rem(5px);
      font-size: rem(46px);
    }
    &__title {
      font-size: rem(90px);
    }
    &__products {
      height: 95vh;
    }
    &__buttons {
      gap: rem(46px) 0;
    }
  }
  @media (min-width: $md) and (orientation: landscape) {
    position: relative;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    .container {
      position: relative;
      padding-top: vh(130px);
    }
    &__palm {
      bottom: rem(-20px);
      height: rem(777px);
      right: 0;
    }

    &__subtitle {
      margin-bottom: rem(20px);
      font-size: rem(36px);
      line-height: 1;
    }
    &__title {
      margin-bottom: rem(36px);
      font-size: rem(82px);
      line-height: vremh(100px);
      text-align: left;
    }
    &__suptitle {
      margin-bottom: rem(54px);
      font-size: rem(26px);
      letter-spacing: vremh(0.5px);
    }
    &__products {
      position: absolute;
      top: 0;
      left: 0;
      bottom: auto;
      width: auto;
      height: auto;
      max-height: 163vh;
    }
    &-block {
      position: absolute;
      right: rem(-15px);
      top: vh(215px);
      padding: rem(30px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: rem(30px);
    }
    &__buttons {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 0 rem(47px);
    }
  }
  @media (min-width: $md) and (max-height: 750px) {
  }

  @media (max-width: $xlg) {
  }
}
</style>
