var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_c('form',{key:_vm.indexForm,staticClass:"feedback__form"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
      },attrs:{"label":"E-mail","placeholder":"Ваш E-mail","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{class:{ error: _vm.validationStatus(_vm.$v.name) || _vm.errorMessage.name },attrs:{"label":"Имя","placeholder":"Ваше имя","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
          ? 'Обязательное поле'
          : !_vm.$v.name.rus && _vm.$v.name.$error
          ? 'Введите текст на русском языке'
          : '') || _vm.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('Select',{class:{
        error: _vm.validationStatus(_vm.$v.subject) || _vm.errorMessage.subject,
      },attrs:{"color":"grey","options":[
        { id: 1, label: 'Регистрация в акции' },
        { id: 2, label: 'Призовой фонд' },
        { id: 3, label: 'Вопросы по правилам' },
        { id: 4, label: 'Технические вопросы' },
        { id: 5, label: 'Другое' },
      ],"labelText":"Тема","placeholder":"Тема обращения","error":(!_vm.$v.subject.required && _vm.$v.subject.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.subject},on:{"input":function($event){_vm.subject = $event}}}),_c('textArea',{key:_vm.indexForm2,class:{
        error: _vm.validationStatus(_vm.$v.text) || _vm.errorMessage.text,
      },attrs:{"label":"Текст обращения","field":_vm.text,"error":(!_vm.$v.text.required && _vm.$v.text.$error ? 'Обязательное поле' : '') ||
        _vm.errorMessage.text},on:{"input":function($event){_vm.text = $event}}}),_vm._v(" "),_c('div',{staticClass:"feedback__checkbox_list"},[_c('checkBox',{staticClass:"_white",class:{
          error: _vm.validationStatus(_vm.$v.checkbox) || _vm.errorMessage.checkbox,
        },attrs:{"label":`Я даю согласие на&nbsp;обработку <a href='${_vm.publicPath}docs/terms-of-use.pdf' target='_blank' class='_undeline-link'>персональных данных</a>`,"field":_vm.checkbox,"error":_vm.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}})],1),_c('div',{staticClass:"modal__footer"},[_c('button',{staticClass:"btn btn--bordered w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){_vm.submit(), _vm.gtmEvent('ask_question', 'click', 'internal')}}},[_c('span',[_vm._v("задать вопрос")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }