<template>
  <section class="prizes section" id="prizes">
    <div class="container fxdc">
      <div class="section-title">РОЗЫГРЫШ <br class="visible-xs" />призОВ</div>
      <div class="prizes__content">
        <div class="prizes__content-block">
          <div class="prizes__content-title gradient-text">Гарантированные</div>
          <div class="prizes__list">
            <div v-for="(item, i) in prizesGarant" :key="i" class="prizes-item">
              <div class="prizes-item__content">
                <div class="prizes-item-img-wrapper">
                  <img
                    :src="require(`../assets/images/prizes/${item.img}`)"
                    class="prizes-item-img"
                  />
                </div>
                <div class="prizes-item__text" v-html="item.text"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="prizes__content-block">
          <div class="prizes__content-title gradient-text">еженедельные</div>
          <div class="prizes__list _with-gradient">
            <div
              v-for="(item, i) in prizes"
              :key="i"
              class="prizes-item"
              :class="item.className"
            >
              <div class="prizes-item__content">
                <div class="prizes-item-img-wrapper">
                  <img
                    :src="require(`../assets/images/prizes/${item.img}`)"
                    class="prizes-item-img"
                  />
                </div>
                <div class="prizes-item__text" v-html="item.text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="prizes-main-prize">
      <div class="prizes-main-prize__title section-title">
        Главный <br class="visible-xs" />приз<sup class="gradient-text">*</sup>
      </div>
      <img
        src="../assets/images/prizes/main-prize-2.png"
        alt=""
        class="prizes-main-prize__img hidden-xs"
      />
      <img
        src="../assets/images/prizes/main-prize-m.png"
        alt=""
        class="prizes-main-prize__img visible-xs"
      />
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      prizesGarant: [
        {
          img: "prize-1.png",
          imgM: "prize-1-m.png",
          text: "<br>пополнение баланса номера <br>мобильного телефона <br>от&nbsp;20₽ до&nbsp;1000₽",
        },
        {
          img: "prize-5.png",
          imgM: "prize-5.png",
          text: "<br>45&nbsp;дней подписки <br>на&nbsp;онлайн-кинотеатр <br>&laquo;PREMIER&raquo;",
        },
      ],
      prizes: [
        {
          img: "prize-2.png",
          imgM: "prize-2-m.png",
          text: "СЕРТИФИКАТ НА&nbsp;ПОКУПКУ ТЕХНИКИ <br class='hidden-xs'>В&nbsp;ИНТЕРНЕТ-МАГАЗИНЕ RED SOLUTION",
        },
        {
          img: "prize-3.png",
          imgM: "prize-3-m.png",
          text: "чайник RED SOLUTION <br>COLORSENSE 120D",
        },
        {
          img: "prize-4.png",
          imgM: "prize-3-m.png",
          text: "МИНИПЕКАРНЯ <br>RED SOLUTION M607",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.prizes {
  position: relative;
  align-items: center;
  padding-top: rem(75px);
  padding-bottom: rem(0px);
  flex-direction: column;
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    top: 80%;
    left: 100%;
    transform: translate(-50%, -50%);
    display: block;
    @include size(rem(1000px));
    background: url("../assets/images/shadow.svg") no-repeat center;
    background-size: contain;
    pointer-events: none;
    z-index: -10;
  }
  &::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 0%;
    transform: translate(-50%, -50%);
    display: block;
    @include size(rem(1080px));
    background: url("../assets/images/shadow.svg") no-repeat center;
    background-size: contain;
    pointer-events: none;
    z-index: -10;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      margin-bottom: rem(20px);
      font-size: rem(16px);
      font-family: $bold;
      text-transform: uppercase;
      text-align: center;
    }
    &-block {
      &:not(:last-child) {
        margin-bottom: rem(30px);
      }
    }
  }
  .container {
    position: relative;
    z-index: 1;
  }
  &__list {
    width: 80%;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include gradient-bg($black, rem(16px));
    &::before {
      content: "";
      position: absolute;
      top: 70%;
      left: 100%;
      transform: translate(-50%, -50%);
      display: block;
      @include size(rem(1500px));
      background: url("../assets/images/shadow.svg") no-repeat center;
      background-size: contain;
      z-index: 0;
    }
  }
  .section-title {
    margin-bottom: rem(46px);
  }
  &-item {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: rem(20px) rem(21px);

    &:last-of-type {
      margin-bottom: 0;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-img {
      max-width: 90%;
      max-height: rem(150px);
      &._alsfe {
        align-self: flex-end;
      }
      &-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto rem(20px);
        ._big & {
          position: absolute;
          top: rem(-25px);
          left: 50%;
          margin: 0;
          transform: translate(-50%, -50%);
          height: rem(112px);
        }
        ._small & {
          max-width: 60%;
        }
      }
    }
    &__text {
      font-size: rem(9px);
      line-height: 1.3;
      font-family: $light;
      text-transform: uppercase;
      text-align: center;
    }
    .btn {
      min-width: auto;
      margin-top: auto;
      pointer-events: none;
      cursor: default;
    }
  }
  &-main-prize {
    position: relative;
    width: 100%;
    margin: rem(68px) auto 0;
    &__title.section-title {
      margin-bottom: 0;
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: rem(10px);
      align-items: center;
      text-align: center;
      background: #fff;
      border-radius: rem(20px);
    }
    &__img {
      width: 100%;
    }
  }

  @media (orientation: landscape) {
    padding-top: rem(155px);
    padding-bottom: rem(0px);
    overflow: visible;
    z-index: 1;
    &::before {
      top: 30%;
      left: 100%;
      @include size(rem(2500px));
    }
    &::after {
      top: 40%;
      left: 0%;
      @include size(rem(1080px));
    }
    .container {
      display: flex;
      padding-top: rem(0px);
    }
    .section-title {
      margin-bottom: rem(120px);
    }
    &__content {
      padding-bottom: 0;
      padding-top: 0;
      flex-direction: row;
      &-title {
        margin-bottom: rem(67px);
        font-size: rem(30px);
      }
      &-block {
        flex: 0px;
        &:not(:last-child) {
          margin-right: rem(50px);
          margin-bottom: 0;
        }
      }
    }
    &__text {
      font-size: rem(18px);
      line-height: rem(25px);
      text-align: center;
    }
    &__list {
      width: 100%;
      margin: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      &::before {
        display: none;
      }
      &._with-gradient {
        &::before {
          content: "";
          position: absolute;
          top: 70%;
          left: 100%;
          transform: translate(-50%, -50%);
          display: block;
          @include size(rem(2500px));
          background: url("../assets/images/shadow.svg") no-repeat center;
          background-size: contain;
          z-index: 0;
        }
      }
    }
    &-item {
      flex-direction: row;
      flex: 0px;
      margin: 0 rem(10px) 0;
      padding: rem(26px) rem(10px) rem(34px);
      max-height: 100%;
      border-radius: rem(30px);
      &._big {
        flex: 0 0 36%;
        height: auto;
        padding-top: rem(156px);
        padding-bottom: rem(38px);
        order: 0;
      }
      &__content {
        width: 100%;
        height: 100%;
        display: flex;
      }
      &-img {
        width: auto;
        max-width: 90%;
        max-height: 100%;
        &-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: rem(264px);
          max-height: none;
          margin: 0 auto rem(35px);
          ._big & {
            position: absolute;
            top: 4%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);
            height: rem(175px);
          }
        }
        ._small & {
          max-width: 90%;
        }
      }

      &__text {
        margin: 0 auto rem(6px);
        font-size: rem(16px);
        line-height: 1.15;
        text-align: center;
      }
      .btn {
        margin-top: auto;
        pointer-events: none;
        cursor: default;
      }
    }
    &-main-prize {
      position: relative;
      margin: rem(150px) auto rem(0px);
      &__content {
        position: absolute;
        top: 0;
        left: rem(60px);
        width: rem(470px);
        height: 100%;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__title.section-title {
        margin-bottom: rem(40px);
      }
      &__suptitle {
        font-size: rem(36px);
      }
      &__text {
        font-size: rem(26px);
      }
      &__sum {
        margin-bottom: rem(15px);
        font-size: rem(100px);
        line-height: 1;
      }
    }
  }
}
</style>
