<template>
  <div class="table_holder w-100">
    <div class="table--desktop table">
      <div class="table__header">
        <div class="table__header-row">
          <div
            class="table__header-col"
            v-for="(item, index) in thead"
            :key="index"
            :style="{ width: item.size }"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="table__inner">
        <table>
          <tbody>
            <slot name="tbody"></slot>
          </tbody>
        </table>
      </div>
    </div>
    <div class="table--mobile table">
      <div class="table__inner">
        <slot name="mobile"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["thead"],
  data: () => ({}),
};
</script>

<style lang="scss">
.table_holder {
  td.status-bad {
    color: #912982;
  }
  td.status-moderation {
    color: #fff;
  }
  .error_icon {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: rem(17px);
    height: rem(17px);
    font-size: rem(12px);
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(1, 63, 93, 0.15);
    border-radius: 50%;
  }
}
.table {
  width: 100%;
  margin: 0 auto;
  font-size: rem(20px);
  &-info-trigger {
    display: block;
    margin-left: rem(8px);
  }
  &__header {
    width: 100%;
    margin-bottom: rem(10px);
    &-row {
      display: flex;
      border: 1px solid;
      border-image-slice: 1;
      border-left-width: 0px;
      border-right-width: 0px;
      border-style: solid;
      border-image-source: linear-gradient(
        134.09deg,
        #e3ba72 0%,
        #fdf5ca 45.83%,
        #ddb06b 70.31%
      );
    }
    &-col {
      position: relative;
      padding: rem(24px) rem(10px) rem(25px);
      color: #fff;
      flex-grow: 0;
      flex-shrink: 0;
      line-height: 1;
      text-align: left;
      white-space: nowrap;
      font-family: $medium;
      // &:first-of-type {
      //   padding-left: 0;
      // }
      // &:last-of-type {
      //   padding-right: 0;
      // }
    }
  }
  &--desktop {
    display: none !important;
    tr {
      margin-bottom: rem(10px);
      vertical-align: middle;
    }
    td {
      position: relative;
      padding: rem(28px) rem(10px) rem(27px);
      color: #fff;
      font-size: rem(18px);
      line-height: 1;
      font-family: $light;
      overflow: hidden;
      img {
        width: rem(50px);
        height: rem(50px);
        object-fit: cover;
        border-radius: rem(5px);
        cursor: pointer;
      }
      span {
        margin-right: rem(10px);
      }
      small {
        display: block;
        font-size: rem(12px);
        text-transform: none;
      }
      &:first-of-type {
        border-radius: rem(14px) 0 0 rem(14px);
      }
      &:last-of-type {
        border-radius: 0 rem(14px) rem(14px) 0;
      }
      .btn {
        width: rem(200px);
        flex-shrink: 0;
        margin-left: auto;
        font-size: rem(12px);
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          td {
            background: rgba(255, 255, 255, 0.2);
          }
        }
        path {
        }
        &._not-accepted {
          color: #f90025;
          path {
            fill: #f90025;
          }
        }
        &._accepted {
          color: #000;
          path {
            fill: #000;
          }
        }
        &._received {
          color: #209524;
          path {
            fill: #209524;
          }
        }
      }
    }
    .table-phone {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-size: rem(20px);
      input {
        font-size: rem(20px);
        background: transparent;
      }
      svg {
        flex-shrink: 0;
        width: rem(24px);
        margin-right: rem(9px);
      }
    }
  }
  &__inner {
    width: 100%;
    // max-height: rem(375px);
    min-height: rem(250px);
    // overflow-y: auto;
    // &::-webkit-scrollbar {
    //   width: rem(3px);
    // }
    // /* Track */
    // &::-webkit-scrollbar-track {
    //   background: rgba(255, 227, 202, 0.5);
    //   border-radius: 25px;
    // }

    // /* Handle */
    // &::-webkit-scrollbar-thumb {
    //   background: $red;
    //   border-radius: 10px;
    //   transition: all 0.3s ease-in-out;
    // }
  }
  table {
    position: relative;
    width: 100%;
    text-align: left;
    z-index: 1;
  }

  &--mobile {
    position: relative;
    display: block;
    width: 100%;
    font-size: rem(14px);
    line-height: 1.2;
    font-family: $light;
    background: $green;
    border-radius: rem(10px);
    .table__inner {
      width: 100%;
      padding: rem(4px) rem(20px);
      &::-webkit-scrollbar {
        width: rem(4px);
        opacity: 1;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 25px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $brown;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
      }
      & > table {
        & > tr {
          & > td {
            padding: rem(16px) 0;
            border-bottom: 1px solid $yellow;
          }
          &:last-child {
            & > td {
              border-bottom: none;
            }
          }
        }
      }
    }
    .table-mobile__inner {
      width: 100%;
      tr {
        vertical-align: middle;
        &:first-of-type {
          td {
          }
        }
        &:last-of-type {
          td {
          }
        }
      }
      td {
        padding: rem(3px) rem(10px);
        text-align: left;
        &:first-of-type {
          width: 20%;
          white-space: nowrap;
          padding-left: 0;
        }
        small {
          display: block;
          text-transform: none;
          font-size: rem(11px);
        }
        img {
          width: rem(50px);
          height: rem(50px);
          border-radius: rem(5px);
          object-fit: cover;
        }
        .btn {
          min-width: auto;
          font-size: rem(11px);
        }
        .table-phone {
          display: flex;
          align-items: flex-start;
          margin-top: rem(5px);
          font-size: rem(12px);
          svg {
            flex-shrink: 0;
            width: rem(18px);
            margin-right: rem(9px);
          }
          input {
            font-size: rem(12px);
            text-align: left;
            background: transparent;
          }
        }
      }
    }
  }
  ._profile {
    width: 95%;
    .table-mobile__inner {
      background: none;
      td {
        padding: 0;
        &:first-child {
          width: rem(66px);
          padding-right: rem(16px);
        }
      }
    }
    tr {
      vertical-align: top;
    }
    td {
      padding: rem(16px) 0px;
      font-size: rem(16px);
      text-align: left;
      font-family: $reg;
    }
    .btn {
      height: rem(38px);
      padding: 0 rem(20px);
      margin-top: rem(10px);
      font-size: rem(14px);
      border-radius: rem(10px);
    }
    td:last-child {
      border-right: 0;
    }
  }
  @media (orientation: landscape) {
    &--desktop {
      display: block !important;
    }
    &--mobile {
      display: none !important;
    }
    &__inner {
      // max-height: rem(365px);
      // padding-right: rem(23px);
      // &::-webkit-scrollbar {
      //   width: rem(6px);
      // }
      // /* Track */
      // &::-webkit-scrollbar-track {
      //   background: rgba(0, 0, 0, 0.1);
      //   border-radius: 25px;
      // }

      // /* Handle */
      // &::-webkit-scrollbar-thumb {
      //   background: $brown;
      //   border-radius: 30px;
      //   transition: all 0.3s ease-in-out;
      // }
    }
  }
}
</style>
