<template>
  <Modal
    name="common_success"
    size="md"
    :successIcon="true"
    :title="title"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="success_modal">
        <p
          class="modal__text success_modal__text _centered-text"
          v-if="text"
          v-html="text"
        ></p>
      </div>
      <div class="modal__footer">
        <button
          v-if="btnClose"
          @click="closeModal('common_success')"
          class="btn btn--bordered w-100"
        >
          Хорошо
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      text: "",
      title: "Спасибо!",
      btnClose: false,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },

    beforeOpen(event) {
      this.text = event.params.text;
      if (event.params.title) {
        this.title = event.params.title || "Спасибо!";
      }
      this.btnClose = event.params.btnClose;
    },
  },
};
</script>
<style lang="scss">
.success_modal-btn {
  margin-top: rem(30px);
}
</style>
