<template>
  <section class="steps section" id="steps">
    <div class="container">
      <div class="steps__content">
        <h2 class="section-title">как принять участие?</h2>
        <div class="steps__list">
          <div class="steps__item" v-for="(item, idx) in steps" :key="idx">
            <div class="steps__item-index">{{ idx + 1 }}</div>
            <div class="steps__item-img-wrapper">
              <img
                :src="require('../assets/images/steps/' + item.img)"
                alt=""
                class="steps__item-img"
              />
            </div>
            <div class="steps__item-content">
              <div
                class="steps__item-title gradient-text"
                v-html="item.title"
              ></div>
              <div class="steps__item-descr" v-html="item.text"></div>
              <!-- <button
                v-if="item.button === true"
                @click="toCheckUpload()"
                class="btn btn--primary hidden-xs"
              >
                <span>зарегистрировать чек</span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      steps: [
        {
          img: "step_1.png",
          title: "Купите",
          text: "любой чай Greenfield <br>в&nbsp;магазине &laquo;Пятёрочка&raquo;",
        },
        {
          img: "step_2.png",
          title: "Зарегистрируйте",
          text: "чек на&nbsp;сайте <br>или в&nbsp;<a href='https://t.me/promo_greenfield_bot' class='_undeline-link' target='_blank'>чат-боте</a>",
        },
        {
          img: "step_3.png",
          title: "Получите ",
          text: "гарантированный приз <br>от&nbsp;20₽ до&nbsp;1000₽<br>или подписку &laquo;PREMIER&raquo;",
        },
        {
          img: "step_4.png",
          title: "Участвуйте <br><small>в розыгрыше призов</small>",
        },
      ],
    };
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.steps {
  position: relative;
  display: flex;
  padding-top: rem(65px);
  padding-bottom: rem(0px);
  z-index: 12;
  &::before {
    content: "";
    position: absolute;
    top: 80%;
    left: 100%;
    transform: translate(-50%, -50%);
    display: block;
    @include size(rem(1000px));
    background: url("../assets/images/shadow.svg") no-repeat center;
    background-size: contain;
    pointer-events: none;
    z-index: -10;
  }
  &::after {
    content: "";
    position: absolute;
    top: 10%;
    left: 0%;
    transform: translate(-50%, -50%);
    display: block;
    @include size(rem(1080px));
    background: url("../assets/images/shadow.svg") no-repeat center;
    background-size: contain;
    pointer-events: none;
    z-index: -10;
  }
  .container {
    position: relative;
    z-index: 99;
  }
  &__content {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    z-index: 9;
  }
  .section-title {
    margin-bottom: rem(40px);
  }
  &__list {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__item {
    max-width: 100vw;
    width: 100%;
    position: relative;
    padding-bottom: rem(18px);
    margin-bottom: rem(30px);
    @include gradient-bg($black, rem(16px));
    &-content {
      width: 100%;
    }
    &-index {
      position: absolute;
      top: 0;
      left: 50%;
      @include size(rem(32px));
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      @include gradient-bg($black, 50%);
      font-family: $medium;
    }
    &-img {
      width: 100%;
      object-fit: contain;
      &-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        margin-right: rem(14px);
      }
    }
    &-title {
      margin-bottom: rem(5px);
      font-size: rem(16px);
      line-height: rem(25px);
      font-family: $bold;
      text-transform: uppercase;
    }
    &-descr {
      margin: 0 auto;
      font-size: rem(10px);
      line-height: rem(13px);
      font-family: $light;
      color: #f8f8f8;
      text-transform: uppercase;
      a,
      span {
        text-decoration: none;
      }
      img {
        display: inline;
      }
    }
    &-monetka {
      height: rem(49px);
      margin: rem(-15px) auto 0;
    }
    .btn {
      margin-top: rem(10px);
    }
  }
  @media (orientation: landscape) {
    padding-top: rem(100px);
    padding-bottom: rem(0px);
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
    .section-title {
      margin-bottom: rem(123px);
    }
    &__content {
      padding-top: rem(50px);
    }
    &__list {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      gap: 0 rem(18px);
    }

    &__item {
      flex: auto;
      margin-bottom: 0;
      border-radius: rem(19px);
      &-index {
        @include size(rem(56px));
        font-size: rem(36px);
      }
      &-img {
        &-wrapper {
          max-width: 100%;
          height: rem(308px);
          margin-bottom: rem(22px);
          margin-right: 0;
        }
      }
      .btn {
        margin: rem(22px) auto 0;
      }
      &-small {
        min-height: rem(18px);
        margin-bottom: rem(5px);
        margin-left: rem(45px);
        text-align: left;
        font-size: rem(15px);
        line-height: rem(18px);
      }
      &-title {
        margin-bottom: rem(12px);
        font-size: rem(22px);
        line-height: rem(26px);
        letter-spacing: 0.03em;
      }
      &-descr {
        margin: auto;
        font-size: rem(16px);
        line-height: rem(19px);
        letter-spacing: 0.03em;
        white-space: nowrap;
      }
    }
  }
  @media (orientation: landscape) {
    .container {
      display: flex;
      align-items: center;
    }
    &__list {
      margin: auto;
      margin-top: 0;
      padding-bottom: 0;
    }
  }
}
@keyframes umbrella {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
