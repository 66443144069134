<template>
  <Modal
    name="temporarily_unavailable"
    title="Акция завершена!"
    size="lg"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="success_modal">
        <div class="modal__text" style="text-align: center">
          Уважаемые участники и&nbsp;гости сайта!
          <br />Акция завершена. <br />Определение победителей и&nbsp;вручение
          призов осуществляется скоро
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {};
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>
