<template>
  <Modal
    name="info"
    title="как Получить приз"
    size="lg"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="modal__text">
        <br />
        <span
          >Для подтверждения статуса победителя необходимо
          в&nbsp;течение&nbsp;2&nbsp;(двух) рабочих дней с&nbsp;момента
          получения данного письма выслать на&nbsp;адрес электронной почты
          <br />Организатора
          <a href="mailto:info@promo-greenfield.ru" class="_undeline-link"
            >info@promo-greenfield.ru</a
          >
          следующие достоверные документы/сведения о&nbsp;себе:</span
        >
        <ul>
          <li v-for="(li, i) in list" :key="i">
            <i>{{ i + 1 }}</i>
            <span v-html="li.text"></span>
          </li>
        </ul>
        <p>
          <br />В&nbsp;соответствии с Приложением 1&nbsp;к настоящим
          <a href="/docs/rules.pdf" class="_undeline-link" target="_blank"
            >Правилам</a
          >. Участник обязан хранить оригинал указанного согласия
          на&nbsp;обработку персональных данных в&nbsp;течение&nbsp;1 (одного)
          года
        </p>
        <br />
        <p>
          После положительной проверки полученной от&nbsp;Победителя информации,
          указанной в&nbsp;п.&nbsp;8.3. Правил, и&nbsp;соблюдения раздела
          3&nbsp;
          <a href="/docs/rules.pdf" class="_undeline-link" target="_blank"
            >Правил</a
          >, Организатор направляет Победителю подтверждение статуса Победитель,
          форму Акта приема-передачи Приза для ознакомления.
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      list: [
        {
          text: "Фамилия, имя, отчество;",
        },
        {
          text: "Контактный номер телефона;",
        },
        {
          text: "Дата рождения;",
        },
        {
          text: "Фото/скан страниц своего паспорта гражданина&nbsp;РФ (разворот с&nbsp;фотографией и&nbsp;страница с&nbsp;информацией о&nbsp;последнем месте регистрации);",
        },
        {
          text: "Фото/скан свидетельства ИНН;",
        },
        {
          text: 'Фото/скан подписанного согласия на&nbsp;<a href="/docs/policy.pdf" class="_undeline-link" target="_blank">обработку персональных данных</a>',
        },
        {
          text: "Иные документы и информацию, необходимые для вручения приза Победителю, по запросу Организатора",
        },
      ],
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>
