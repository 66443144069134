<template>
  <Modal
    name="premier__popup"
    size="sm"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <img src="../../assets/images/prizes/prize-5.png" class="modal__premier" alt="">
      <div class="modal__promocode">Промокод: <span class="_red">{{ code || 0 }}</span></div>
      <div class="modal__text">
        <p>45&nbsp;дней подписки на&nbsp;онлайн-кинотеатр &laquo;PREMIER&raquo;. 
          <br>Предложение действует для новых пользователей. </p>
        <p>Активировать промокод можно до&nbsp;31.12.2024&nbsp;на <a href="https://premier.one/profile/promocode" target="_blank" class="_undeline-link">https://premier.one/profile/promocode</a> 
          <br>Подробные условия акции: <a href="https://premier.one/info/tntp/promocode" target="_blank" class="_undeline-link">https://premier.one/info/tntp/promocode</a> </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      code: null,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.code = event.params.code;
    },
  },
};
</script>
