<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="container">
        <img src="../assets/images/logo.svg" alt="" class="footer__logo" />
        <ul class="footer__docs">
          <li v-for="(item, idx) in docs" :key="idx" class="footer__docs-item">
            <a
              :href="publicPath + 'docs/' + item.path"
              class="footer__docs-link"
              target="_blank"
              @click="
                item.gtm
                  ? (gtmEvent('rules', 'click', 'send'),
                    gtmEvent('rules', 'click', 'internal'))
                  : ''
              "
            >
              {{ item.name }}
            </a>
          </li>
          <!-- <li class="footer__docs-item" @click="$modal.show('feedback')">
            <span>обратная связь</span>
          </li> -->
        </ul>
        <div class="footer__text">
          <p>
            Для участия в&nbsp;акции необходимо: в&nbsp;период
            с&nbsp;1&nbsp;августа по&nbsp;30&nbsp;сентября 2024&nbsp;г.
            приобрести в&nbsp;магазинах торговой сети &laquo;Пятёрочка&raquo;
            или в&nbsp;приложении &laquo;Пятёрочка&raquo; любую упаковку чая
            Greenfield <sup class="reg">&reg;</sup>
            и&nbsp;зарегистрировать чек с&nbsp;покупкой на&nbsp;сайте
            promo-greenfield.ru. Общий срок проведения акции (включая срок
            выдачи призов): с&nbsp;1&nbsp;августа по&nbsp;15&nbsp;октября
            2024&nbsp;г. Количество призов ограничено. Призы могут отличаться
            от&nbsp;изображений на&nbsp;рекламных материалах. Информация
            об&nbsp;организаторе акции, сроках и&nbsp;правилах
            её&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке
            вручения призов размещена на&nbsp;сайте promo-greenfield.ru.
            <br />* Главный приз разыгрывается среди тех, кто зарегистрировал
            3&nbsp;чека или более.
          </p>
        </div>
        <!-- <div class="footer__copyright">
          © <span>{{ year }}</span> Все права защищены.
        </div> -->
      </div>
    </div>
    <div
      v-if="user?.existCheck"
      class="join-club"
      :class="{ hide: !showPrize }"
    >
      <div class="join-club__close" @click="showPrize = false"></div>
      <a href="https://greenfield-club.ru/?utm_source=promo-greenfield.ru" target="_blank">
        <img
          src="../assets/images/promo-present.png"
          alt=""
          class="join-club__img"
        />
        <button class="btn btn--bordered btn--sm join-club__btn">
          Вступайте в клуб
        </button>
      </a>
    </div>
  </footer>
</template>
<script>
export default {
  components: {},
  props: ["partners"],
  data() {
    return {
      showPrize: true,
      publicPath: process.env.BASE_URL,
      docs: [
        {
          name: "правила акции",
          path: "rules.pdf",
          gtm: true,
        },
        {
          name: "политика конфиденциальности",
          path: "policy.pdf",
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollTo() {
      const faq = document.getElementById("faq").offsetTop;
      window.scroll({
        top: faq,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    year() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(30px) 0 rem(45px);
  border-top: 1.33px solid rgba(38, 38, 38, 1);
  border-bottom: none;
  color: #fff;
  overflow: hidden;
  z-index: 100;
  &__logo {
    height: rem(30px);
    margin: 0 auto rem(30px);
  }
  &__row,
  &__docs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__docs {
    &-item {
      margin-bottom: rem(20px);
      font-size: rem(12px);
      line-height: 1;
      text-transform: uppercase;
      font-family: $light;
      letter-spacing: 0.06em;
    }
  }
  &__text,
  &__copyright {
    font-size: rem(9px);
    line-height: rem(13px);
    text-align: center;
    font-family: $light;
    color: rgba(228, 201, 180, 0.7);
    p {
      margin-bottom: rem(5px);
      // overflow-wrap: break-word;
      // word-wrap: break-word;
      // -webkit-hyphens: auto;
      // -ms-hyphens: auto;
      // -moz-hyphens: auto;
      // hyphens: auto;
    }
    a {
      white-space: nowrap;
    }
  }
  &__col {
    width: 100%;
  }
  &__copyright {
    margin-top: rem(12px);
  }
  @media (max-width: $sm) {
    .container {
      padding-left: rem(13px);
      padding-right: rem(17px);
    }
  }
  @media (orientation: landscape) {
    padding: rem(77px) 0 rem(50px);
    font-size: rem(13px);
    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 80%;
      transform: translate(-50%, -50%);
      display: block;
      @include size(rem(1500px));
      background: url("../assets/images/shadow.svg") no-repeat center;
      background-size: contain;
      pointer-events: none;
      z-index: -10;
    }
    .container {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &__logo {
      height: rem(68px);
      margin-bottom: rem(65px);
    }
    &__content {
      padding-top: 0;
      background: none;
    }
    &__row,
    &__docs {
      flex-direction: row;
      justify-content: center;
    }
    &__docs {
      margin-bottom: rem(55px);
      text-transform: uppercase;
      &-item {
        margin-bottom: 0;
        line-height: normal;
        font-size: rem(25px);
        cursor: pointer;
        &:not(:last-child) {
          margin-right: rem(90px);
        }
      }
    }
    &__copyright {
      margin-top: 0;
      text-align: left;
      font-size: rem(16px);
    }
    &__text {
      font-size: rem(18px);
      line-height: rem(36px);
    }
    &__col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:last-of-type {
        flex: 0 0 58%;
      }
    }
  }
}

.join-club {
  position: fixed;
  right: 5vw;
  bottom: 0vh;
  transition: 0.7s;
  z-index: 10000;
  &__close {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    @include size(rem(30px));
    border: 2px solid #fff;
    background: rgba($green, 0.6)
      url("../assets/images/icons/close-white.svg") no-repeat center;
    background-size: 50%;
    border-radius: 50%;
    cursor: pointer;
  }
  &__img {
    height: rem(150px);
  }
  &__btn.btn {
    position: absolute;
    bottom: 30%;
    left: 50%;
    max-width: 100vw;
    transform: translateX(-50%);
    font-size: rem(10px);
  }
  &.hide {
    transform: translateX(150%);
  }
  @media (orientation: landscape) {
    &__close {
      @include size(rem(40px));
    }
    &__img {
      height: rem(350px);
    }
    &__btn.btn {
      font-size: rem(20px);
    }
  }
}
</style>
