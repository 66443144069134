<template>
  <div id="app">
    <Header :headerModified="headerModified" v-if="showHeader" />
    <div class="app-content">
      <router-view />
    </div>
    <Footer v-if="showFooter" />
    <Apmcheck v-if="apmInit" />
    <Feedback />
    <Authorization />
    <Signup />
    <Recover />
    <EnterCode />
    <SuccessSignup />
    <SuccessRecover />
    <SuccessProfile />
    <CommonError />
    <CommonSuccess />
    <TemporarilyUnavailable />
    <ImagePopup />
    <VideoPopup />
    <Soc_signup />
    <Soc_signupFinish />
    <ChangePassword />
    <ErrorCode />
    <CommonErrorUpload />
    <InfoPopup />
    <NewPassword />
    <Delivery />
    <UpdateInfo />
    <Premier />
    <RedPopup />
  </div>
</template>
<script>
import RedPopup from './components/modals/redPopup.vue';
import Premier from './components/modals/premier.vue';
import UpdateInfo from './components/modals/updateInfo.vue';
import Delivery from "./components/modals/delivery.vue";
import NewPassword from "./components/modals/newPassword.vue";
import Apmcheck from "./components/Apmcheck.vue";
import InfoPopup from "./components/modals/infoPopup.vue";
import CommonSuccess from "./components/modals/commonSuccess.vue";
import Header from "@/components/AppHeader.vue";
import Footer from "@/components/AppFooter.vue";
import Feedback from "@/components/modals/feedback.vue";
import TemporarilyUnavailable from "@/components/modals/temporarilyUnavailable.vue";

import SuccessSignup from "@/components/modals/successSignup.vue";
import SuccessRecover from "@/components/modals/successRecover.vue";
import SuccessProfile from "@/components/modals/successProfile.vue";

//import UploadCheck from "@/components/modals/uploadCheck.vue";
import CommonError from "@/components/modals/commonError.vue";

import store from "./store";
import Authorization from "./components/modals/authorization.vue";
import Signup from "./components/modals/signup.vue";
import Recover from "./components/modals/recover.vue";
import ImagePopup from "./components/modals/imagePopup.vue";
import Soc_signup from "./components/modals/soc_signup.vue";
import Soc_signupFinish from "./components/modals/soc_signup-finish.vue";
import ChangePassword from "./components/modals/changePassword.vue";
import ErrorCode from "./components/modals/errorCode.vue";
import EnterCode from "./components/modals/enterCode.vue";
import VideoPopup from "./components/modals/videoPopup.vue";
import CommonErrorUpload from "./components/modals/commonErrorUpload.vue";
export default {
  components: {
    Header,
    Footer,
    Feedback,
    SuccessSignup,
    SuccessRecover,
    SuccessProfile,
    CommonError,
    TemporarilyUnavailable,
    CommonSuccess,
    Authorization,
    Signup,
    Recover,
    ImagePopup,
    Soc_signup,
    Soc_signupFinish,
    ChangePassword,
    ErrorCode,
    EnterCode,
    VideoPopup,
    CommonErrorUpload,
    InfoPopup,
    Apmcheck,
    NewPassword,
    Delivery, UpdateInfo, Premier, RedPopup
  },
  data: function () {
    return {
      apmInit: false,
      showHeader: true,
      showFooter: true,
      headerModified: true,
      path: window.location.pathname,
      hash: window.location.hash,
    };
  },
  methods: {
    setHeight() {
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vhH", `${vh}px`);
    },
    showModal(name) {
      this.$modal.show(name);
    },
    checkAuth(t) {
      if (this.$store.getters.token) {
        if (!this.$store.getters.user) {
          this.$store.dispatch("GetProfile").then((response) => {
            if (response) {
              console.log(response);
              setTimeout(function () {
                t.apmInit = true;
              }, 1000);
            }
          });
        }
      } else {
        setTimeout(() => {
          if (this.$route.name === "Profile") {
            this.$router.push({ name: "Home" });
          }
        }, 500);
      }
    },
  },
  mounted() {
    // this.$store.dispatch("LogOut");
    // if (!localStorage.getItem("first")) {
    //   this.$modal.show("temporarily_unavailable");
    //   localStorage.setItem("first", true);
    // }
    const t = this;
    this.setHeight();
    window.addEventListener("resize", this.setHeight);
    this.$root.$on("onLogined", function () {
      t.apmInit = true;
    });
    this.checkAuth(t);
    // if (this.$route.path != '/') {
    //   document.getElementById('app').classList.add(this.path.split('/').pop());
    // }
    // localStorage.clear();
  },

  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || "";
        console.log(to);
      },
      immediate: true,
    },

    "$route.path"(value) {
      let navs = document.querySelectorAll(".header__nav-link");
      if (value != "/") {
        setTimeout(() => {
          // document.getElementById("app").classList.add(value.split("/").pop());
        }, 100);

        navs.forEach((nav) => {
          nav.classList.remove("active");
        });
      }
    },
  },
};
</script>

<style lang="scss">
.app-content {
  flex: 1;
  @media (min-width: 640px) {
    display: flex;

    & > div {
      flex: 1;
      max-width: 100%;
    }
  }
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
  min-width: 320px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
