var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"update-info"},[_c('inputText',{key:_vm.indexForm,class:{ error: _vm.validationStatus(_vm.$v.name) || _vm.errorMessage.name },attrs:{"label":"Имя","placeholder":"Ваше имя","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
        ? 'Обязательное поле'
        : !_vm.$v.name.rus && _vm.$v.name.$error
        ? 'Введите текст на русском языке'
        : '') || _vm.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{key:_vm.indexForm,class:{
        error: _vm.validationStatus(_vm.$v.phone) || this.errorMessage.phone,
      },attrs:{"label":"Телефон","placeholder":"+7 (___) ___-__-__","field":_vm.phone,"type":"tel","mask":'+7(###) ###-##-##',"error":(!_vm.$v.phone.required && _vm.$v.phone.$error
          ? 'Обязательное поле'
          : !_vm.$v.phone.minLength && _vm.$v.phone.$error
          ? 'Минимум 11 символов'
          : '') || this.errorMessage.phone},on:{"input":function($event){_vm.phone = $event}}}),_c('div',{staticClass:"recover__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить"),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }