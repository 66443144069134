<template>
  <div id="apm-widget"></div>
</template>

<script>
export default {
  name: "Apmcheck",

  data() {
    return {
      ready: false,
    };
  },

  methods: {},
  created() {},
  mounted() {
    this.ready = true;

    const t = this;

    //console.log("apm");

    const widgetParams = {
      api: "https://api.apmcheck.ru",
      apiKey: "427674a3-35d9-411c-9fa5-bb40820e7100",
      userUuid: this.$store.getters.user.profile.userUuid,
      // sendPhotosAsArray: true,
      callbacks: {
        onReceiptSentSuccess: function () {
          localStorage.setItem("checkDone", true);
          t.$root.$emit("onCheckSended");
          t.adhighCheckSeccess();
          t.$metrika.reachGoal("receipt_registration_success");
          // t.gtmEvent("do", "receipt_registration_success");
          t.$store.dispatch("GetCodes", { page: 1 });
          t.$store.dispatch("GetProfile");
          t.$router.push({ name: "Profile", query: { tab: "profile_checks" } });
        },
      },
      i18n: {
        labels: {
          mainButton: "сканировать qr-код чека",
          manualQrButton: "ввести данные вручную ",
          uploadPhotosButton: "загрузить фото чека ",
          submitManualQrButton: "Отправить",
          addPhotoButton: "загрузить фото",
          submitPhotosButton: "Отправить",
        },
        screens: {
          scanQr: {
            header: "регистрация чека",
            subheader:
              "Для сканирования чека наведите камеру на&nbsp;qr&nbsp;-&nbsp;код",
          },
          manualInput: {
            header: "регистрация чека",
            subheader: "Введите данные с чека вручную",
          },
          cameraNotAvailable: {
            subheader:
              '<span class="amp-error"><strong>Нет доступа к&nbsp;камере</strong> Мы&nbsp;не&nbsp;можем получить доступ к&nbsp;камере устройства. Разрешите браузеру обращаться к&nbsp;камере или введите данные с&nbsp;чека вручную.</span>',
          },
          qrParsed: {
            header: "ваш чек успешно ЗАГРУЖЕН",
            subheader:
              "Проверка чека может занимать до&nbsp;72&nbsp;часов. После того, как чек пройдет модерацию, вы&nbsp;получите письмо на&nbsp;почту, указанную при регистрации",
          },
          uploadFiles: {
            header: "Загрузка фото",
            subheader: "Добавьте фото чека (не более 5 частей)",
            fileTooLargeError:
              "Файл больше 10 МБ. Загрузите файл меньшего размера.",
          },
          sentReceiptFailed: {
            header: "Не удалось отправить чек!",
          },
        },
      },
    };

    window.qrWidget.init("apm-widget", widgetParams);
  },

  components: {},
};
</script>

<style lang="scss">
.apm-modal-backdrop {
  background-color: rgba($green, 0.2) !important;
  backdrop-filter: blur(6px) !important;
}
#apm-widget {
  left: -100%;
  position: absolute;
}
#apm-modal {
  .apm-block-qr-video {
    margin-bottom: rem(40px);
  }
  .apm-qr-video {
    width: rem(250px);
    height: rem(250px);
    margin: auto;
    border: none;
    background: #fff url("../assets/images/focus.png") no-repeat center;
    background-size: 85%;
  }
  .apm-modal-header {
    padding: 0;
    width: 100%;
  }
  .apm-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: calc(100% - rem(30px));
    max-width: 100vw;
    padding: rem(50px) rem(20px) rem(34px);
    background: $black;
    color: #fff;
    border: none;
    border-radius: rem(20px);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 30%;
      left: 100%;
      transform: translate(-50%, -50%);
      display: block;
      @include size(rem(1000px));
      background: url("../assets/images/shadow.svg") no-repeat center;
      background-size: contain;
      z-index: -10;
      pointer-events: none;
    }
    &::after {
      content: "";
      position: absolute;
      top: 40%;
      left: 0%;
      transform: translate(-50%, -50%);
      display: block;
      @include size(rem(880px));
      background: url("../assets/images/shadow.svg") no-repeat center;
      background-size: contain;
      pointer-events: none;
      z-index: -10;
    }
  }
  .apm-modal-body {
    width: 100%;
    max-width: 100%;
    margin: auto;
    padding: 0;
  }
  .apm-modal-dialog {
    display: flex;
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .apm-title {
    display: block;
    font-size: rem(20px);
    margin-bottom: rem(30px);
    font-weight: normal;
    text-transform: uppercase;
    color: #fff;
    font-family: $bold;
    text-align: center;
  }
  .apm-description-title .apm-p {
    margin-top: rem(-20px);
    margin-bottom: rem(20px);
    font-size: rem(12px);
    font-family: $light;
    text-transform: uppercase;
    .apm-p {
      text-transform: uppercase;
    }
  }
  .amp-error {
    display: block;
    margin: auto;
    padding: 0;
    color: #fff;
    font-size: rem(10px);
    line-height: 1.2;
    text-transform: uppercase;
    font-family: $medium;
    &::before {
      content: "";
      display: block;
      @include size(rem(66px));
      margin: 0 auto rem(20px);
      background: url("../assets/images/error.svg") no-repeat center;
      background-size: contain;
    }
    strong {
      display: block;
      margin-bottom: rem(10px);
      font-size: rem(20px);
      font-family: $bold;
    }
  }

  #apm-submit-qr {
    margin-top: rem(25px);
    text-align: center;
  }
  .apm-validation-error {
    max-width: rem(250px);
    position: absolute;
    right: 0;
    margin-top: calc(-#{$field-height-sm} - #{rem(25px)});
    transform: translateY(-100%);
    padding: rem(6px) rem(10px);
    text-align: left;
    font-family: $medium;
    text-transform: none;
    margin-left: rem(28px);
    font-size: rem(9px);
    background: #fff;
    border-radius: rem(6px);
    color: #ff001f;
    &:empty {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: rem(10px);
      width: 0;
      height: 0;
      margin-bottom: rem(-15px);
      transform: rotate(30deg);
      border-top: 18px solid #fff;
      border-right: 12px solid transparent;
      z-index: 1;
    }
  }

  #apm-upload-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: rem(128px);
    margin-bottom: rem(10px);
    border-radius: rem(15px) !important;
    border: none;
    background: $green;
    box-sizing: border-box;
    border-radius: 0px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23DDB06CFF' stroke-width='4' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: rem(10px);
    }
    &::after {
      display: none;
    }
    .btn-content {
      font-size: rem(12px) !important;
      line-height: rem(20px);
      text-align: center;
      font-family: $medium;
      letter-spacing: normal !important;
      text-transform: uppercase;
      color: #fff !important;
      &:before {
        content: "";
        display: block;
        width: rem(32px);
        height: rem(32px);
        margin: 0 auto rem(13px);
        background: url("../assets/images/icons/upload.svg") no-repeat center;
        background-size: contain;
      }
    }
  }
  #apm-photos-thumbnails-container {
    display: flex;
    height: rem(54px);
    gap: 0 rem(8.3px);
    background: url("../assets/images/borders.svg") no-repeat center;
    background-size: contain;
  }
  .apm-close {
    cursor: pointer;
    position: absolute;
    top: rem(25px);
    right: rem(20px);
    width: rem(16px);
    height: rem(16px);
    max-width: none;
    max-height: none;
    background-color: transparent;
    background-image: url("../assets/images/icons/close-white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 0%;
    transition: 0.4s;
    &:hover {
      transform: rotate(90deg);
    }

    &:before,
    &:after {
      display: none;
    }
  }

  .apm-photo-thumbnail-image {
    object-fit: cover;
    border-radius: rem(8px);
  }

  .apm-photo-thumbnail-wrapper {
    flex: 0 0 17.6%;
    width: auto;
    height: 100%;
    margin: 0;
    border: 1px solid #dfb572;
    padding: 0;
    border-radius: rem(8px);
    background: rgba(#000, 0.15);
  }

  .apm-remove-photo-button {
    top: rem(3px);
    left: auto;
    right: rem(3px);
    @include size(rem(22px));
    border-radius: rem(10px);
    background: url("../assets/images/icons/del.svg") no-repeat center;
    background-size: contain;
    opacity: 1;
    &::before,
    &::after {
      display: none;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .apm-block-btn {
    width: 100%;
    box-shadow: none;
  }
  .apm-btn-manual-input {
    margin-top: rem(15px);
  }
  .apm-btn,
  #apm-submit-photos-button .apm-block-btn,
  #apm-submit-qr .apm-block-btn,
  #apm-scan-qr-dupe .apm-block-btn {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: rem(45px);
    width: 100%;
    cursor: pointer;
    padding: rem(14px) rem(20px);
    line-height: 1;
    transition: all 0.3s ease-in-out;
    border: 2.08px solid rgba(181, 181, 181, 1);
    background: rgba(181, 181, 181, 0.2);
    box-sizing: border-box;
    overflow: visible;
    border-radius: rem(10px);
    z-index: 1;
    .btn-content,
    .apm-qr-btn-text {
      font-size: rem(13px);
      font-family: $medium !important;
      text-align: center;
      white-space: nowrap;
      color: #fff;
    }
  }

  .apm-p-input {
    position: relative;
    display: block;
    padding: 0;
    padding-top: rem(8px);
    margin-bottom: rem(-20px);
    margin-left: rem(20px);
    font-size: $field-font-size-sm - rem(4px);
    transition: 0.4s;
    color: $field-placeholder;
    font-weight: 400;
    @media (orientation: landscape) {
      padding-top: vh(5px);
      margin-bottom: vh(-30px);
      margin-left: $field-offset-lg;
      font-size: $field-font-size-lg - vh(6px);
    }
  }

  .apm-qr-input {
    width: 100%;
    height: $field-height-sm;
    padding: $field-padding-sm;
    margin-bottom: $field-offset-sm;
    max-width: 100%;
    appearance: none !important;
    text-align: left;
    font-size: $field-font-size-sm;
    background: transparent;
    border-radius: $field-border-radius-sm;
    transition: all 0.3s ease-in-out;
    border: $field-border;
    color: $field-color;
    font-family: $light;
    text-transform: none;
    ._centered-text & {
      text-align: center;
    }
    ._bordered & {
      height: rem(56px);
      text-transform: uppercase;
      background: #fff2e5;
      border: 1px solid $red;
    }
    &::placeholder {
      color: $field-placeholder;
      font-size: $field-font-size-sm;
      ._bordered & {
        color: $red;
        font-family: $reg;
        opacity: 1;
      }
    }
    &:focus,
    &:not(:placeholder-shown),
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      outline: none;
      border-color: $green_light;
      & ~ .form__caption {
        transform: scale(0.6) translateY(#{rem(-10px)});
        @media (min-width: $md) and (orientation: landscape) {
          transform: scale(0.6) translateY(#{rem(-18px)});
        }
      }
    }
    &._has-placeholder {
      outline: none;
      & ~ .form__caption {
        transform: scale(0.6) translateY(#{rem(-10px)});
        @media (min-width: $md) and (orientation: landscape) {
          transform: scale(0.6) translateY(#{rem(-13px)});
        }
      }
    }
    &[name="name"] {
      text-transform: none;
    }
    &:disabled {
      pointer-events: none;
      background: #d9d9d9;
      border-color: #d9d9d9;
    }
  }
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: none;
  }
  .apm-p {
    font-family: $light;
    margin-bottom: rem(30px);
    font-size: rem(20px);
    color: #fff;
    a._red {
      display: inline-block;
      margin-top: rem(14px);
    }
  }
  .apm-img-error {
    display: none;
  }
  #apm-scan-qr-dupe {
    .apm-action-button-dupe {
      background: linear-gradient(
        134.09deg,
        #e3ba72 0%,
        #fdf5ca 45.83%,
        #ddb06b 70.31%
      );
      border: none;
      .apm-qr-btn-text {
        color: $grey;
      }
    }
  }
  @media (orientation: landscape) {
    display: flex;

    .apm-modal-dialog {
      max-width: rem(800px);
      margin: rem(40px) auto auto;
    }
    .apm-btn {
      width: rem(456px);
    }
    .apm-modal-content {
      min-height: auto;
    }
  }
  @media (orientation: landscape) {
    .apm-p {
      font-size: rem(20px);
    }
    .apm-btn {
      width: rem(256px);
    }
    .apm-modal-dialog {
      max-width: rem(688px);
    }
    .apm-modal-content {
      margin-top: rem(50px);
      border-radius: rem(50px);
      padding: vh(85px) vh(110px) vh(50px);
    }
    .apm-close {
      max-width: none;
      max-height: none;
      top: vh(30px);
      right: vh(30px);
      @include size(vh(60px));
      background-size: rem(24px);
    }
    .apm-description-title .apm-p {
      margin-bottom: vh(25px);
      font-size: vh(20px);
      .apm-p {
        text-transform: uppercase;
      }
    }
    .amp-error {
      font-size: rem(18px);
      &::before {
        @include size(rem(66px));
        margin: 0 auto rem(32px);
      }
      strong {
        margin-bottom: rem(30px);
        font-size: rem(35px);
      }
    }
    .apm-block-btn,
    .apm-btn,
    #apm-submit-photos-button .apm-block-btn {
      width: 100%;
      height: vh(54px);
      padding: vh(19px) rem(10px);
      .btn-content,
      .apm-qr-btn-text {
        font-size: vh(20px);
      }
    }
    .apm-btn,
    #apm-submit-photos-button .apm-block-btn,
    #apm-submit-qr .apm-block-btn,
    #apm-scan-qr-dupe .apm-block-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: vh(70px);
    }
    #apm-submit-qr .apm-action-button,
    #apm-scan-qr-dupe .apm-action-button,
    #qr-upload-photos-button .apm-btn {
      height: vh(70px);
      .btn-content,
      .apm-qr-btn-text {
        font-size: vh(20px);
      }
    }
    .apm-title {
      font-size: vh(35px);
    }
    #qr-upload-photos-button,
    #apm-submit-qr {
      .apm-btn {
        height: vh(70px);
      }
      .btn-content,
      .apm-qr-btn-text {
        font-size: vh(20px);
      }
    }
    #apm-upload-button {
      width: rem(400px);
      height: rem(200px);
      margin-bottom: rem(32px);
      .btn-content {
        font-size: vh(22px) !important;
        line-height: vh(21px);
        &::before {
          width: rem(70px);
          height: rem(70px);
        }
      }
    }
    #apm-photos-thumbnails-container {
      width: rem(400px);
      height: rem(71px);
      margin: auto;
      gap: 0 rem(12px);
    }
    .apm-photo-thumbnail-wrapper {
      height: 100%;
    }
    #apm-scan-qr-dupe {
      .apm-action-button-dupe {
      }
    }
    .apm-validation-error {
      padding: rem(8px) rem(21px);
      margin-top: calc(-#{$field-height-lg} - #{rem(35px)});
      font-size: rem(15px);
    }
    .apm-submit-qr,
    .apm-btn-manual-input {
      margin-top: vh(32px);
    }
    .apm-qr-input {
      height: $field-height-lg;
      margin-bottom: $field-offset-bottom-lg;
      padding: $field-padding-lg;
      font-size: $field-font-size-lg;
      border-radius: $field-border-radius-lg;
      &::placeholder {
        font-size: $field-font-size-lg;
      }
    }
  }
}
</style>
