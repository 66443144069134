import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ComingSoon from "../views/ComingSoon.vue";

Vue.use(VueRouter);

const titlePrefix = "СЕЗОН ПРИЗОВ ОТ GREENFIELD";
const store = require("../store");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // redirect: new Date() >= new Date('2024-08-01') ? false : '/coming-soon',
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/profile",
    // redirect: new Date() >= new Date('2024-08-01') ? false : '/coming-soon',
    name: "Profile",
    component: () => import("../views/account/Profile.vue"),
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      title: titlePrefix + "Акция начнется 1 августа 2024 г.",
    },
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: Home },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    if (to.hash) {
      position = {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      position = { x: 0, y: 0 };
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position);
      }, 10);
    });
  },
});

export default router;
