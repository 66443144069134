<template>
  <form class="update-info" >
    <inputText
    :key="indexForm"
      label="Имя"
      placeholder="Ваше имя"
      :field="name"
      @input="name = $event"
      :class="{ error: validationStatus($v.name) || errorMessage.name }"
      :error="
        (!$v.name.required && $v.name.$error
          ? 'Обязательное поле'
          : !$v.name.rus && $v.name.$error
          ? 'Введите текст на русском языке'
          : '') || errorMessage.name
      "
    />
    <inputText
    :key="indexForm"
        label="Телефон"
        placeholder="+7 (___) ___-__-__"
        :field="phone"
        @input="phone = $event"
        type="tel"
        :mask="'+7(###) ###-##-##'"
        :class="{
          error: validationStatus($v.phone) || this.errorMessage.phone,
        }"
        :error="
          (!$v.phone.required && $v.phone.$error
            ? 'Обязательное поле'
            : !$v.phone.minLength && $v.phone.$error
            ? 'Минимум 11 символов'
            : '') || this.errorMessage.phone
        "
      />

    <div class="recover__footer">
      <button
        type="button"
        class="btn btn--primary"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Отправить<img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required, minLength } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "UpdateInfoForm",
  props: ["user"],
  data() {
    return {
      ready: false,
      index: 1,
      indexForm: 1,
      phone: null,
      name: null,
      surname: null,
      errorMessage: {
        name: null,
        surname: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    name: { required, rus },
    phone: { required, minLength: minLength(11) },
  },
  methods: {
    errorReset() {
      console.log("34");
      this.$v.$reset();

      this.errorMessage = {
        name: null,
        phone: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit() {
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("UpdateProfile", {
            name: this.name,
            phone: this.phone,
          })
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.name) {
                [this.errorMessage.name] = r.error.name;
                fieldsError = true;
              }
              if (r.error && r.error.phone) {
                [this.errorMessage.phone] = r.error.phone;
                fieldsError = true;
              }
              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.error,
                });
              }
            } else {
              this.submitStatus = null;

              this.$modal.hide("update_info");
              this.$modal.show("common_success", {
                text: "Данные успешно обновлены!",
              });
              setTimeout(() => {
                // document.location.reload();
              }, 1000);
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.name = this.user.name;
      this.phone = this.user.phone;
      this.indexForm++;
    }, 500);
    
  },
  components: {
    inputText,
  },
};
</script>

<style lang="scss">
.recover {
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(35px);
    text-align: center;
  }
  .btn {
    margin-bottom: rem(15px);
  }
}
</style>
