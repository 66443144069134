var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_c('form',{staticClass:"feedback__form"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.delivery_fio) || _vm.errorMessage.delivery_fio,
      },attrs:{"label":"Фио","placeholder":"Введите имя","field":_vm.delivery_fio,"error":(!_vm.$v.delivery_fio.required && _vm.$v.delivery_fio.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.delivery_fio},on:{"input":function($event){_vm.delivery_fio = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.delivery_phone) || _vm.errorMessage.delivery_phone,
      },attrs:{"label":"Номер телефона","placeholder":"+7 (xxx)xxx-xx-xx","field":_vm.delivery_phone,"error":(!_vm.$v.delivery_phone.required && _vm.$v.delivery_phone.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.delivery_phone,"type":"tel","mask":'+7(###) ###-##-##'},on:{"input":function($event){_vm.delivery_phone = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.delivery_city) || _vm.errorMessage.delivery_city,
      },attrs:{"label":"Город","placeholder":"Введите город","field":_vm.delivery_city,"error":(!_vm.$v.delivery_city.required && _vm.$v.delivery_city.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.delivery_city},on:{"input":function($event){_vm.delivery_city = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.delivery_region) ||
          _vm.errorMessage.delivery_region,
      },attrs:{"label":"Регион","placeholder":"Введите регион","field":_vm.delivery_region,"error":(!_vm.$v.delivery_region.required && _vm.$v.delivery_region.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.delivery_region},on:{"input":function($event){_vm.delivery_region = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.delivery_street) ||
          _vm.errorMessage.delivery_street,
      },attrs:{"label":"Улица","placeholder":"Введите название улицы","field":_vm.delivery_street,"error":(!_vm.$v.delivery_street.required && _vm.$v.delivery_street.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.delivery_street},on:{"input":function($event){_vm.delivery_street = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.delivery_house) || _vm.errorMessage.delivery_house,
      },attrs:{"label":"Дом","placeholder":"Введите номер дома","field":_vm.delivery_house,"error":(!_vm.$v.delivery_house.required && _vm.$v.delivery_house.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.delivery_house},on:{"input":function($event){_vm.delivery_house = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.building) || _vm.errorMessage.building,
      },attrs:{"label":"Строение","placeholder":"Номер строения","field":_vm.building,"error":_vm.errorMessage.building},on:{"input":function($event){_vm.building = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.building2) || _vm.errorMessage.building2,
      },attrs:{"label":"Корпус","placeholder":"Номер корпуса","field":_vm.building2,"error":_vm.errorMessage.building2},on:{"input":function($event){_vm.building2 = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.delivery_flat) || _vm.errorMessage.delivery_flat,
      },attrs:{"label":"Квартира","placeholder":"Введите номер квартиры","field":_vm.delivery_flat,"error":_vm.errorMessage.delivery_flat},on:{"input":function($event){_vm.delivery_flat = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.zipcode) || _vm.errorMessage.zipcode,
      },attrs:{"label":"Индекс","placeholder":"XXXXXX","field":_vm.zipcode,"error":(!_vm.$v.zipcode.required && _vm.$v.zipcode.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.zipcode},on:{"input":function($event){_vm.zipcode = $event}}}),_c('div',{staticClass:"modal__note"},[_vm._v(" Персональные данные, отправленные через эту форму, будут использованы только для ответа на конкретный вопрос, а также в целях аналитики, и не будут использоваться в маркетинговых целях. ")]),_c('checkBox',{staticClass:"_white",class:{
        error: _vm.validationStatus(_vm.$v.checkbox) || _vm.errorMessage.checkbox,
      },attrs:{"label":"Мне исполнилось 18 лет<sup class='_red'>*</sup>","field":_vm.checkbox,"error":_vm.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}}),_c('div',{staticClass:"modal__footer"},[_c('button',{staticClass:"btn btn--primary w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("Отправить")]),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }