<template>
  <div class="signup">
    <div class="signup__fields">
      <inputText
        label="Имя"
        placeholder="Введите имя"
        :field="first_name"
        :class="{
          error: validationStatus($v.first_name) || errorMessage.first_name,
        }"
        :error="
          (!$v.first_name.required && $v.first_name.$error
            ? 'Обязательное поле'
            : !$v.first_name.rus && $v.first_name.$error
            ? 'Введите текст на русском языке'
            : '') || errorMessage.first_name
        "
        @input="first_name = $event"
      />
      <inputText
        label="Фамилия"
        placeholder="Введите фамилию"
        :field="last_name"
        :class="{
          error: validationStatus($v.last_name) || errorMessage.last_name,
        }"
        :error="
          (!$v.last_name.required && $v.last_name.$error
            ? 'Обязательное поле'
            : !$v.last_name.rus && $v.last_name.$error
            ? 'Введите текст на русском языке'
            : '') || errorMessage.last_name
        "
        @input="last_name = $event"
      />
      <inputText
        label="Телефон"
        placeholder="+7 (___) ___-__-__"
        :field="phone"
        @input="phone = $event"
        type="tel"
        :mask="'+7(###) ###-##-##'"
        :class="{
          error: validationStatus($v.phone) || this.errorMessage.phone,
        }"
        :error="
          (!$v.phone.required && $v.phone.$error
            ? 'Обязательное поле'
            : !$v.phone.minLength && $v.phone.$error
            ? 'Минимум 11 символов'
            : '') || this.errorMessage.phone
        "
      />
      <inputText
        label="E-mail"
        placeholder="Введите e-mail "
        :field="email"
        type="email"
        :class="{
          error: validationStatus($v.email) || this.errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || this.errorMessage.email
        "
        @input="email = $event"
      />
    </div>

    <div class="signup__checkbox_list">
      <checkBox
        color="white"
        :label="`Согласие на обработку <a href='${publicPath}docs/terms-of-use.pdf' class='_undeline-link' target='_blank'>персональных данных</a> и <a href='${publicPath}docs/policy.pdf' class='_undeline-link' target='_blank'>политикой конфиденциальности</a>`"
        :field="agree_pd"
        :class="{
          error: validationStatus($v.agree_pd) || errorMessage.agree_pd,
        }"
        :error="errorMessage.agree_pd"
        @input="agree_pd = $event"
      />
      <checkBox
        color="white"
        :label="`Согласие с <a href='${publicPath}docs/rules.pdf' class='_undeline-link' target='_blank'>условиями Акции</a> `"
        :field="rules"
        :class="{
          error: validationStatus($v.rules) || errorMessage.rules,
        }"
        :error="errorMessage.rules"
        @input="rules = $event"
      />
    </div>
    <div class="signup__footer">
      <button
        type="button"
        class="btn btn--bordered w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        <span>Регистрация</span>
        <img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
      <div class="modal__another-option">
        <p>
          Уже есть аккаунт?
          <a href="#" class="_undeline-link" @click="showLogInModal()">Войти</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";

import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "SignupForm",
  props: ["network"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ready: false,
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      // password: null,
      // repPassword: null,
      agree_pd: false,
      rules: false,
      errorMessage: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        // password: null,
        // repPassword: null,
        agree_pd: null,
        rules: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
    first_name: { required, rus },
    last_name: { required, rus },
    phone: { required, minLength: minLength(11) },

    agree_pd: {
      required,
      sameAs: sameAs(() => true),
    },
    rules: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  watch: {
    checkbox(newVal) {
      if (!newVal) {
        this.$store.commit("SET_AGREE", false);
      }
    },
  },
  methods: {
    selectSuggestion(suggestion) {
      this.city = suggestion.data.city;
    },
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,

        // repPassword: null,
        // password: null,
        agree_pd: null,
      };
    },
    showRules() {
      if (!this.$store.getters.agree) {
        this.$modal.show("document_popup", {
          src: this.publicPath + "docs/rules.pdf",
        });
      }
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setFormErrors(e) {
      const errors = e.response.data;
      this.submitStatus = null;

      let fieldsError = null;

      for (let index = 0; index < errors.length; index++) {
        const element = errors[index];
        this.errorMessage[element.field] = element.message;
        fieldsError = true;
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors[0].message,
        });
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = {
          login: this.email,
          name: this.first_name,
          surname: this.last_name,
          phone: this.phone,
          // pass: this.password,
          // city: this.city,
          rules1: true,
          rules3: true,
        };
        this.$store
          .dispatch("SignUp", formData)
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email || "";
                fieldsError = true;
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
              }
              if (r.error && r.error.phone) {
                [this.errorMessage.phone] = r.error.phone;
                fieldsError = true;
              }
              if (r.error && r.error.surname) {
                [this.errorMessage.last_name] = r.error.surname;
                fieldsError = true;
              }
              if (r.error && r.error.name) {
                [this.errorMessage.first_name] = r.error.name;
                fieldsError = true;
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  title: r.error[0],
                });
              }
            } else {
              this.$modal.hide("signup");
              this.$metrika.reachGoal("registration_success");
              this.$modal.show("success_signup", {
                email: this.email,
                title: "Ваша регистрация ПРошла успешно",
              });
              this.gtmEvent("do", "registration_success");
              this.adhighRegistrationSuccess();
              localStorage.removeItem("utm_source");
              localStorage.setItem("utm_source_done", true);
              // this.$store.commit("SET_TOKEN", r.data[0]);

              // this.$store.dispatch("GetProfile").then(() => {
              //   this.$router.push({ name: "Profile"});
              // });
            }
          })
          .catch((e) => {
            this.submitStatus = null;
            console.log(e);
          });
      }
    },
    showLogInModal() {
      this.$modal.hide("signup");
      this.$modal.show("authorization");
    },
  },
  mounted() {
    console.log(this.$route);
  },
  components: {
    inputText,
    checkBox,
  },
};
</script>

<style lang="scss" scoped>
.signup {
  &__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__checkbox_list {
    display: flex;
    flex-direction: column;
    gap: rem(15px) 0;
    margin-top: rem(15px);
    margin-bottom: rem(20px);
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (orientation: landscape) {
    &__row {
      flex-direction: row;
    }
    &__checkbox_list {
      margin-top: vh(30px);
      margin-bottom: vh(30px);
      gap: vh(15px) 0;
    }
  }
}
</style>
