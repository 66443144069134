<template>
  <Modal
    name="red__popup"
    size="sm"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <img src="../../assets/images/prizes/red.png" class="modal__red" alt="">
      <div class="modal__red-title">СЕРТИФИКАТ
        <br><span>4000₽</span></div>
      <div class="modal__text">На&nbsp;покупку техники 
        <br>в&nbsp;интернет-магазине RED SOLUTION</div>
      <div class="modal-certificate">
        <div class="modal-certificate__title gradient-text">Код подарка</div>
        <div class="modal-certificate__code">{{ code || 0 }}</div>
        <a href="https://redsolution.company" target="_blank" class="btn btn--primary">Перейти  в магазин</a>
      </div>
      <div class="modal__text">Инструкция по&nbsp;активации доступна <br>в&nbsp;Дополнении 1&nbsp;к <a :href="`${publicPath}docs/rules.pdf`" target="_blank" class="_undeline-link">правилам акции</a>.</div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      code: null,
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.code = event.params.code;
    },
  },
};
</script>
