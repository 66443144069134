<template>
  <div class="feedback">
    <form class="feedback__form" :key="indexForm">
      <inputText
        label="E-mail"
        placeholder="Ваш E-mail"
        :field="email"
        @input="email = $event"
        :class="{
          error: validationStatus($v.email) || errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || errorMessage.email
        "
      />
      <inputText
        label="Имя"
        placeholder="Ваше имя"
        :field="name"
        @input="name = $event"
        :class="{ error: validationStatus($v.name) || errorMessage.name }"
        :error="
          (!$v.name.required && $v.name.$error
            ? 'Обязательное поле'
            : !$v.name.rus && $v.name.$error
            ? 'Введите текст на русском языке'
            : '') || errorMessage.name
        "
      />
      <Select
        color="grey"
        :options="[
          { id: 1, label: 'Регистрация в акции' },
          { id: 2, label: 'Призовой фонд' },
          { id: 3, label: 'Вопросы по правилам' },
          { id: 4, label: 'Технические вопросы' },
          { id: 5, label: 'Другое' },
        ]"
        labelText="Тема"
        placeholder="Тема обращения"
        @input="subject = $event"
        :class="{
          error: validationStatus($v.subject) || errorMessage.subject,
        }"
        :error="
          (!$v.subject.required && $v.subject.$error
            ? 'Обязательное поле'
            : '') || errorMessage.subject
        "
      />
      <textArea
        label="Текст обращения"
        :field="text"
        :key="indexForm2"
        @input="text = $event"
        :class="{
          error: validationStatus($v.text) || errorMessage.text,
        }"
        :error="
          (!$v.text.required && $v.text.$error ? 'Обязательное поле' : '') ||
          errorMessage.text
        "
      />
      <div class="feedback__checkbox_list">
        <checkBox
          :label="`Я даю согласие на&nbsp;обработку <a href='${publicPath}docs/terms-of-use.pdf' target='_blank' class='_undeline-link'>персональных данных</a>`"
          :field="checkbox"
          class="_white"
          :class="{
            error: validationStatus($v.checkbox) || errorMessage.checkbox,
          }"
          :error="errorMessage.checkbox"
          @input="checkbox = $event"
        />
      </div>
      <div class="modal__footer">
        <button
          type="button"
          class="btn btn--bordered w-100"
          @click="submit(), gtmEvent('ask_question', 'click', 'internal')"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          <span>задать вопрос</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";
import textArea from "@/components/form/textArea.vue";
import Select from "@/components/form/inputSelect.vue";

import { required, email, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "FeedbackForm",
  data() {
    return {
      publicPath: "/",
      indexForm: 1,
      indexForm2: 1,
      ready: false,
      name: null,
      email: null,
      text: null,
      subject: null,
      checkbox: false,
      checkbox2: false,
      attach: "",
      errorMessage: {
        email: null,
        name: null,
        text: null,
        subject: null,
        checkBox: null,
        checkBox2: null,
      },

      submitStatus: null,

      chosenFileName: {},
    };
  },

  validations: {
    email: { required, email },
    subject: { required },
    name: { required, rus },
    text: { required },
    checkbox: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    message(newVal) {
      if (newVal === null) {
        this.indexForm2++;
      }
    },
  },
  methods: {
    submitMedia(e) {
      this.chosenFileName = e;
      console.log(e[0]);
      this.attach = e[0];
    },
    deleteFile() {
      this.chosenFileName = {};
      this.attach = "";
    },

    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        name: null,
        text: null,
        subject_id: null,
        checkBox: null,
        checkBox2: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit() {
      console.log("submit");
      this.gtmEvent("click", "ask_question");
      this.$v.$touch();
      console.log(this.$v.$pendding || this.$v.$error);
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("SendFeedback", {
            name: this.name,
            email: this.email,
            subject_id: this.subject,
            text: this.text,
            accept1: true,
          })
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError;
              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email;
                fieldsError = true;
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
              }

              if (r.error && r.error.name) {
                [this.errorMessage.name] = r.error.name;
                fieldsError = true;
              }

              if (r.error && r.error.text) {
                [this.errorMessage.message] = r.error.text;
                fieldsError = true;
              }
              if (r.error && r.error.subject_id) {
                [this.errorMessage.subject] = r.error.subject_id;
                fieldsError = true;
              }
              if (r.error && r.error.accept1) {
                [this.errorMessage.checkBox] = r.error.accept1;
                fieldsError = true;
              }
            } else {
              this.indexForm++;
              this.indexForm2++;
              setTimeout(() => {
                this.$v.$reset();
                this.message = null;
              }, 0);
              this.submitStatus = null;
              this.$modal.hide("feedback");
              this.$modal.show("common_success", {
                title: "Спасибо за&nbsp;обращение!",
                text: `Ваш вопрос будет обработан <br>в&nbsp;течение 72&nbsp;часов.<br><br>ответ будет отправлен на&nbsp;${this.email}`,
                btnClose: true,
              });
              this.gtmEvent("feedback_sent");
              this.fbGoal();
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.user) {
        this.indexForm++;
        this.name = this.user.profile.name;
        this.email = this.user.profile.email;
        this.checkbox = true;
        this.checkbox2 = true;
      }
    }, 1000);
  },
  components: {
    inputText,
    textArea,
    checkBox,
    Select,
  },
};
</script>

<style lang="scss"></style>
