<template>
  <label class="form-checkbox" :class="color ? `_${color}` : ''">
    <input
      type="checkbox"
      class="form-checkbox__input"
      @change="onInput()"
      v-model.trim="retValue"
    />
    <div class="form-checkbox__custom"></div>
    <div class="form-checkbox__caption" v-html="label"></div>
  </label>
</template>

<script>
export default {
  data() {
    return {
      retValue: this.field,
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },
  },
  props: {
    field: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  @media (orientation: landscape) {
    margin-bottom: rem(20px);
  }
  &._center {
    align-items: center;
  }

  &__input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    visibility: hidden;
  }

  &__input:checked + .form-checkbox__custom {
    background-image: url("../../assets/images/icons/checked.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
  }

  &__custom {
    min-width: rem(22px);
    width: rem(22px);
    height: rem(22px);
    background-size: rem(18px);
    background: transparent;
    border-radius: rem(5px);
    background-repeat: no-repeat;
    background-position: center;
    ._white & {
      background: #fff;
      border: none;
    }
    // transition: all 0.3s ease-in-out;
  }

  &__caption {
    margin-top: rem(6px);
    margin-left: rem(10px);
    font-size: rem(12px);
    line-height: 1;
    text-transform: none;
    color: #fff;
    font-family: $light;
    a {
      border-bottom: 1px solid #fff;
    }
  }
  @media (orientation: landscape) {
    &__custom {
      min-width: rem(24px);
      width: rem(24px);
      height: rem(24px);
      background-size: rem(20px);
    }
    &__caption {
      margin-left: rem(8px);
      margin-top: rem(5px);
      font-size: rem(14px);
      line-height: 1.3;
    }
  }
}

.error .form-checkbox__custom {
  background: #ff9999;
}
</style>
