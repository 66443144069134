<template>
  <modal
    :name="name"
    :adaptive="true"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
    :clickToClose="!withoutClosing"
  >
    <div
      class="modal"
      :class="[
        'modal-' + name,
        size ? 'modal--' + size : 'modal--md',
        success ? 'modal-success' : '',
        withoutClosing ? '_withoutClosing' : '',
      ]"
    >
      <div
        class="modal__close"
        v-if="!withoutClosing"
        @click="closeModal(name)"
      ></div>
      <img
        v-if="successIcon"
        src="../assets/images/icons/success.svg"
        alt=""
        class="modal__info-icon"
      />
      <img
        v-if="errorIcon"
        src="../assets/images/icons/warning.svg"
        alt=""
        class="modal__info-icon"
      />
      <h2
        v-if="title"
        class="modal__title"
        :class="success ? 'modal-success__title' : ''"
        v-html="title"
      ></h2>
      <slot name="content"></slot>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
    },
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    successIcon: {
      type: Boolean,
    },
    errorIcon: {
      type: Boolean,
    },
    success: {
      type: Boolean,
    },
    withoutClosing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showBurger() {
      this.closeModal(this.name);
      let header = document.querySelector(".header");
      if (!header.classList.contains("header--mob_active"))
        header.classList.add("header--mob_active");
      else header.classList.remove("header--mob_active");
    },
    closeModal(name) {
      this.$emit("closeModal", name);
      if (name === "signup") {
        // this.gtmEvent("drop_success", "do", "internal");
      }
    },
    beforeOpen(event) {
      this.$parent.beforeOpen(event);
    },
  },
  mounted() {
    // this.$modal.show("success_modal");
  },
};
</script>

<style lang="scss">
.vm--container {
  overflow: auto;
  z-index: 999999 !important;
  .vm--overlay {
    background: rgba($green, 0.2);
    backdrop-filter: blur(6px);
  }
}
.vm--modal {
  min-height: 100vh;
  justify-content: center;
  overflow: visible !important;
  display: flex;
  align-items: flex-end;
  box-shadow: none !important;
  background-color: transparent !important;

  @media (orientation: landscape) {
    padding: 0;
    align-items: center;
  }
}

.modal {
  position: absolute;
  top: 4vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: calc(100% - rem(30px));
  max-width: 100vw;
  padding: rem(50px) rem(20px) rem(34px);
  background: $black;
  overflow: hidden;
  border-radius: rem(20px);
  box-shadow: 0px rem(10px) rem(30px) rgba(0, 0, 0, 0.5);
  &::before {
    content: "";
    position: absolute;
    top: 30%;
    left: 100%;
    transform: translate(-50%, -50%);
    display: block;
    @include size(rem(1000px));
    background: url("../assets/images/shadow.svg") no-repeat center;
    background-size: contain;
    pointer-events: none;
    z-index: -10;
    pointer-events: none;
  }
  &::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 0%;
    transform: translate(-50%, -50%);
    display: block;
    @include size(rem(880px));
    background: url("../assets/images/shadow.svg") no-repeat center;
    background-size: contain;
    pointer-events: none;
    pointer-events: none;
    z-index: -10;
  }
  &-check_photo {
    min-height: auto;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(16px);
    height: rem(16px);
    background-image: url("../assets/images/icons/close-white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: 0.4s;
    &:hover {
      transform: rotate(90deg);
    }
  }

  &__title {
    margin-bottom: rem(30px);
    font-size: rem(20px);
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.05em;
    font-family: $bold;
    text-align: center;
    & + .modal__text {
      margin-top: vh(-6px);
      margin-bottom: vh(20px);
    }
  }
  &__subtitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(-20px);
    margin-bottom: rem(10px);
    font-family: $bold;
    font-size: rem(14px);
    text-transform: uppercase;
    text-align: center;
    span {
      display: block;
      flex: 1 0 auto;
      height: 1px;
      background: #000;
      &:first-of-type {
        margin-right: rem(10px);
      }
      &:last-of-type {
        margin-left: rem(10px);
      }
    }
  }
  &__text {
    margin-top: rem(0px);
    text-align: center;
    text-transform: uppercase;
    font-family: $light;
    font-size: rem(12px);
    line-height: 1;
    b {
      display: block;
      text-transform: uppercase;
    }
    ul {
      margin: rem(10px) 0;
      li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: rem(14px);
        font-size: rem(18px);
        text-align: left;
        &:not(:last-of-type) {
          margin-bottom: rem(14px);
        }
      }
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(rem(30px));
        margin-right: rem(14px);
        line-height: 1;
        flex-shrink: 0;
        font-style: normal;
        font-family: $bold;
        font-size: rem(14px);
        background: #deb22e;
        border-radius: 50%;
      }
    }
  }
  &__info-icon {
    margin-bottom: rem(20px);
    max-height: rem(40px);
    width: auto;
    object-fit: contain;
  }
  &__another-option {
    margin: rem(20px) 0 0;
    font-family: $light;
    font-size: rem(12px);
    text-transform: uppercase;
    text-align: center;
    a {
      border-bottom: 1px solid currentColor;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__footer {
    margin-top: rem(20px);
    text-align: center;
    &:empty {
      display: none;
    }
  }
  &__prize {
    display: flex;
    align-items: center;
    margin-bottom: rem(20px);
    &-img {
      width: 100%;
      height: auto;
      margin-right: rem(20px);
      flex-shrink: 0;
      &-big {
        height: rem(140px);
        margin-right: auto;
        margin-left: auto;
      }
    }
    &-text {
      color: $brown;
      font-size: rem(18px);
      line-height: rem(20px);
      text-transform: uppercase;
    }
  }
  .success_modal {
    &__text {
      margin-bottom: 0;
    }
    &-back {
      font-size: rem(12px);
      text-transform: uppercase;
      font-family: $bold;
      text-align: center;
    }
    &-image {
      margin: auto;
    }
  }
  .error_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: rem(14px);
    img {
      margin-bottom: rem(24px);
    }
    &-text {
      font-family: $reg;
      font-size: rem(14px);
      text-transform: none;
      margin-bottom: rem(25px);
    }
  }
  .btn {
    margin-left: auto;
    margin-right: auto;
  }
  &__popover-text {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: rem(20px);
    background: rgba($white, 0.6);
    color: $red;
    transform: translateY(-40px);
    transition: 0.6s ease-in-out;
    &:hover {
      transform: translateY(-100%);
    }
  }
  &-image {
    padding-top: rem(120px);
    padding-bottom: rem(55px);
    img {
      margin: auto;
    }
  }
  &__iframe {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    margin-bottom: rem(30px);
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  &__note {
    margin-bottom: rem(20px);
    font-size: rem(9px);
  }
  &-premier__popup {
    border: rem(2px) solid $yellow;
    img {
      height: rem(48px);
      width: auto;
      margin-bottom: rem(30px);
      object-fit: contain;
    }
    .modal__text {
      margin-bottom: 0;
      text-transform: none;
      font-size: rem(12px);
      line-height: rem(20px);
      p:not(:last-of-type) {
        margin-bottom: rem(20px);
      }
    }
  }
  &-red__popup {
    text-align: center;
    .modal__text {
      text-transform: none;
      margin-bottom: 0;
    }
  }
  &__red {
    max-width: 90%;
    margin:0 auto rem(20px);
    &-title {
      line-height: 1.2;
      font-size: rem(20px);
      span {
        font-family: $medium;
      }
    }
  }
  &-certificate {
    margin: rem(25px) auto rem(20px);
    &__title {
      margin-bottom: rem(15px);
      font-size: rem(15px);
      text-transform: uppercase;
      font-family: $bold;
    }
    &__code {
      display: flex;
      justify-content: center;
      align-items: center;
      
      height: rem(40px);
      padding-top: rem(5px);
      margin-bottom: rem(15px);
      max-width: 100%;
      background: #FFF;
      text-align: center;
      font-size: rem(24px);
      line-height: 1;
      font-family: $light;
      color: #000043;
      border-radius: rem(8px);
    }
  }
  &__promocode {
    display: flex;
    align-items: center;
    height: rem(50px);
    margin: 0 auto rem(30px);
    padding: 0 rem(18px);
    font-size: rem(14px);
    line-height: 1;
    background: #FFF;
    border: rem(2px) solid #E20714;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: rem(20px);
    pointer-events: none;
    color: #000;
    span {
      display: inline-block;
      margin-left: rem(5px);
      font-weight: 900;
      pointer-events: all;
    }
  }
  @media (orientation: landscape) {
    min-height: auto;
    margin-top: 10vh;
    justify-content: flex-start;
    &--sm {
      width: rem(440px);
    }
    &--md {
      width: rem(700px);
    }
    &--lg {
      width: rem(758px);
      padding: rem(40px) rem(25px) rem(46px);
    }
  }
  @media (orientation: landscape) {
    min-height: auto;
    top: 0;
    bottom: auto;
    justify-content: flex-start;
    border-radius: rem(50px);
    padding: rem(75px) rem(66px) rem(53px);
    &--sm {
      width: rem(535px);
      padding: rem(85px) rem(50px);
    }
    &--md {
      width: vh(688px);
      padding: vh(90px) vh(70px) vh(50px);
    }
    &--lg {
      width: rem(800px);
      padding: rem(66px) rem(75px) rem(45px);
      .modal__subtitle {
        margin-bottom: rem(38px);
      }
      &.modal-image {
        margin-top: 10vh;
      }
      &.modal-video {
        width: 70vw;
      }
      &.modal-common_success,
      &.modal-common_error {
        padding-top: rem(100px);
      }
    }
    &-premier__popup {
      img {
        height: rem(68px);
        width: auto;
        margin-bottom: rem(60px);
        object-fit: contain;
      }
      .modal__text {
        margin-bottom: 0;
        text-transform: none;
        font-size: rem(15px);
        line-height: rem(23px);
        p:not(:last-of-type) {
          margin-bottom: rem(20px);
        }
      }
    }
    &__promocode {
      margin: 0 auto rem(60px);
      font-size: rem(18px);
    }
    &-red__popup {
      width: vh(700px);
      padding: vh(37px) vh(130px);
    }
    &__red {
      width: vh(418px);
      margin: 0 auto vh(36px);
      &-title {
        font-size: vh(40px);
      }
    }

    &-certificate {
      width: vh(420px);
      margin: vh(25px) auto vh(20px);
      &__title {
        margin-bottom: vh(20px);
        font-size: vh(20px);
        text-transform: uppercase;
        font-family: $bold;
      }
      &__code {
        display: flex;
        justify-content: center;
        align-items: center;
        
        height: vh(58px);
        padding-top: vh(5px);
        margin-bottom: vh(20px);
        max-width: 100%;
        background: #FFF;
        text-align: center;
        font-size: vh(36px);
        line-height: 1;
        font-family: $light;
        color: #000043;
        border-radius: vh(8px);
      }
      .btn {
        font-size: vh(20px);
        border-radius: vh(12px);
      }
    }
    &-success_signup {
      .modal__title {
        font-size: vh(35px);
        line-height: 1.1;
      }
    }
    &-delivery {
      .modal__title {
        font-size: rem(26px);
        line-height: 1.1;
      }
    }
    &__note {
      margin-bottom: rem(20px);
      font-size: rem(12px);
    }
    &-temporarily_unavailable {
      .modal__title {
        font-size: rem(40px);
      }
      .modal__text {
        font-size: rem(18px);
        line-height: 1.5;
        b {
          font-size: rem(26px);
          text-transform: uppercase;
        }
      }
    }
    &__title {
      margin-bottom: vh(30px);
      font-size: vh(40px);
      line-height: 1;
      letter-spacing: 0.02em;
    }
    &__text {
      margin-bottom: rem(32px);
      font-size: vh(20px);
      line-height: 1.2;
      ul {
        width: 70%;
        margin: rem(15px) auto 0;
        li {
          font-size: rem(20px);
        }
        i {
          @include size(rem(36px));
          font-size: rem(18px);
        }
      }
    }
    &__close {
      top: vh(30px);
      right: vh(30px);
      @include size(vh(60px));
      background-size: rem(24px);
    }
    &-pdf {
      max-height: 60vh;
    }
    &__list {
      width: 70%;
    }
    &__subtitle {
      margin-top: rem(-30px);
      margin-bottom: rem(20px);
      font-size: rem(14px);
    }
    &__another-option {
      margin: vh(30px) 0 0;
      font-size: vh(24px);
    }
    &__prize {
      width: rem(430px);
      margin: 0 auto rem(30px);
      &-img {
        width: rem(380px);
        margin: auto;
        & + .modal__prize-text {
          height: rem(150px);
          margin: 0;
        }
      }
      &-text {
        font-size: rem(22px);
        line-height: rem(25px);
      }
    }
    &__footer {
      width: rem(460px);
      max-width: 100%;
      margin: rem(30px) auto 0;
    }
    &__info-icon {
      margin-bottom: rem(30px);
      max-height: rem(66px);
    }
  }
}
</style>
