<template>
  <header class="header" :class="{ _modified: headerModified }">
    <div class="container">
      <div class="header__row">
        <div class="header__inner">
          <router-link
            :to="{ path: `/${locationSearch}#home` }"
            class="header__logo visible-xs"
          >
            <img
              src="../assets/images/logo.png"
              class="header__logo-img"
              alt=""
            />
          </router-link>
          <a href="/" class="header__red visible-xs">
            <img
              src="../assets/images/red-logo.png"
              alt=""
              class="header__red-logo"
            />
          </a>
          <a href="/" class="header__5ka visible-xs">
            <img
              src="../assets/images/5ka.png"
              alt=""
              class="header__5ka-logo"
            />
          </a>
          <router-link
            v-if="user"
            tag="button"
            :to="{
              name: 'Profile',
              query: {
                tab:
                  $route.name === 'Profile' ? 'profile_data' : 'profile_checks',
              },
            }"
            class="header__lk visible-xs"
          >
            <img src="../assets/images/icons/lk.svg" alt="" />
          </router-link>
          <div
            v-if="!showMenu"
            class="header__hamburger"
            @click="showBurger(), gtmEvent('menu_head', 'do', 'internal')"
          >
            <img
              src="../assets/images/icons/hamburger.svg"
              alt=""
              class="header__hamburger-pic"
            />
          </div>
          <button
            v-else
            class="header__menu_mob-close visible-xs"
            @click="showBurger()"
          ></button>
          <div class="header__nav">
            <router-link
              :to="{ path: `/${locationSearch}#home` }"
              class="header__logo"
            >
              <img
                src="../assets/images/logo.png"
                class="header__logo-img"
                alt=""
              />
            </router-link>
            <a href="/" class="header__red">
              <img
                src="../assets/images/red-logo.png"
                alt=""
                class="header__red-logo"
              />
            </a>
            <a href="/" class="header__5ka">
              <img
                src="../assets/images/5ka.png"
                alt=""
                class="header__5ka-logo"
              />
            </a>
            <ul class="header__nav-list">
              <li
                v-for="nav in navigation"
                :key="nav.name"
                class="header__nav-link"
                :data-menuanchor="nav.anchor"
                @click="$metrika.reachGoal('menu_head')"
                :class="[
                  { active: $route.hash === `#${nav.anchor}` || nav.active },
                ]"
              >
                <router-link
                  v-if="nav.anchor"
                  :to="{ path: `/${locationSearch}`, hash: `#${nav.anchor}` }"
                >
                  {{ nav.name }}
                </router-link>
                <a
                  v-else-if="nav.path"
                  :href="publicPath + 'docs/' + nav.path"
                  class="d-flex aic"
                  target="_blank"
                >
                  {{ nav.name }}
                  <svg
                    width="11"
                    height="13"
                    viewBox="0 0 11 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.2 1C6.2 0.613401 5.8866 0.3 5.5 0.3C5.1134 0.3 4.8 0.613401 4.8 1L6.2 1ZM5.00503 10.945C5.27839 11.2183 5.72161 11.2183 5.99498 10.945L10.4497 6.4902C10.7231 6.21684 10.7231 5.77362 10.4497 5.50025C10.1764 5.22689 9.73316 5.22689 9.4598 5.50025L5.5 9.46005L1.5402 5.50025C1.26684 5.22689 0.82362 5.22689 0.550253 5.50025C0.276886 5.77362 0.276886 6.21684 0.550253 6.4902L5.00503 10.945ZM4.8 1L4.8 10.45L6.2 10.45L6.2 1L4.8 1Z"
                      fill="#F1C27A"
                    />
                    <line
                      x1="0.7"
                      y1="11.95"
                      x2="10.3"
                      y2="11.95"
                      stroke="#F1C27A"
                      stroke-width="1.4"
                      stroke-linecap="round"
                    />
                  </svg>
                </a>
                <router-link v-else :to="{ path: `/${locationSearch}` }">
                  {{ nav.name }}
                </router-link>
              </li>
            </ul>
            <div class="header__user-nav" v-if="user">
              <router-link
                tag="button"
                :to="{
                  name: 'Profile',
                  query: {
                    tab:
                      $route.name === 'Profile'
                        ? 'profile_data'
                        : 'profile_checks',
                  },
                }"
                class="header__login btn btn--primary"
              >
                <div class="header__login-icon-wrapper">
                  <img
                    class="header__login-icon"
                    src="../assets/images/icons/user.svg"
                    alt=""
                  />
                  <img
                    class="header__login-icon"
                    src="../assets/images/icons/user-white.svg"
                    alt=""
                  />
                </div>
                <span>{{ user.first_name || "Личный кабинет" }} </span>
              </router-link>
              <div class="header__logout" @click="logout()">
                <svg
                  width="22"
                  height="25"
                  viewBox="0 0 22 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6318 2.16418H1.71045V20.2541C1.71045 20.9395 1.98272 21.5968 2.46736 22.0814C2.95201 22.5661 3.60933 22.8383 4.29472 22.8383H14.6318M15.9239 16.3777L19.8003 12.5013M19.8003 12.5013L15.9239 8.62486M19.8003 12.5013H6.87899"
                    stroke="url(#paint0_linear_530_155)"
                    stroke-width="2.58427"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_530_155"
                      x1="1.71045"
                      y1="12.5013"
                      x2="19.8003"
                      y2="12.5013"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#9C7041" />
                      <stop offset="0.47" stop-color="#DEB77B" />
                      <stop offset="1" stop-color="#C3803A" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div
              v-else
              class="header__login btn btn--primary"
              @click.prevent="showModal('authorization')"
            >
              <div class="header__login-icon-wrapper">
                <img
                  class="header__login-icon"
                  src="../assets/images/icons/user.svg"
                  alt=""
                />
                <img
                  class="header__login-icon"
                  src="../assets/images/icons/user-white.svg"
                  alt=""
                />
              </div>
              <span>Войти</span>
            </div>
          </div>
        </div>

        <div class="header__menu" :class="{ _active: showMenu }">
          <div class="header__menu-wrapper">
            <router-link
              v-for="(item, idx) in navigation"
              :key="idx"
              class="header__menu-link"
              active-class="_active"
              @click.native="showBurger(), $metrika.reachGoal('menu_head')"
              :to="{ path: `/${locationSearch}#${item.anchor}` }"
            >
              {{ item.name }}
            </router-link>
          </div>
          <div class="header__menu-bottom">
            <div class="header__user-nav" v-if="user">
              <router-link
                tag="button"
                :to="{
                  name: 'Profile',
                  query: {
                    tab:
                      $route.name === 'Profile'
                        ? 'profile_data'
                        : 'profile_checks',
                  },
                }"
                class="header__login btn btn--primary"
                @click.native="showBurger()"
              >
                <div class="header__login-icon-wrapper">
                  <img
                    class="header__login-icon"
                    src="../assets/images/icons/user.svg"
                    alt=""
                  />
                  <img
                    class="header__login-icon"
                    src="../assets/images/icons/user-white.svg"
                    alt=""
                  />
                </div>
                <span>{{ user.first_name || "Личный кабинет" }} </span>
              </router-link>
              <div
                class="header__logout gradient-text"
                @click="logout(), showBurger()"
              >
                <span>Выйти</span>
                <svg
                  width="22"
                  height="25"
                  viewBox="0 0 22 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6318 2.16418H1.71045V20.2541C1.71045 20.9395 1.98272 21.5968 2.46736 22.0814C2.95201 22.5661 3.60933 22.8383 4.29472 22.8383H14.6318M15.9239 16.3777L19.8003 12.5013M19.8003 12.5013L15.9239 8.62486M19.8003 12.5013H6.87899"
                    stroke="url(#paint0_linear_530_156)"
                    stroke-width="2.58427"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_530_156"
                      x1="1.71045"
                      y1="12.5013"
                      x2="19.8003"
                      y2="12.5013"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#9C7041" />
                      <stop offset="0.47" stop-color="#DEB77B" />
                      <stop offset="1" stop-color="#C3803A" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div
              v-else
              class="header__login btn btn--primary"
              @click.prevent="showModal('authorization'), showBurger()"
            >
              <div class="header__login-icon-wrapper">
                <img
                  class="header__login-icon"
                  src="../assets/images/icons/user.svg"
                  alt=""
                />
                <img
                  class="header__login-icon"
                  src="../assets/images/icons/user-white.svg"
                  alt=""
                />
              </div>
              <span>Войти</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["headerModified"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      phone: "7 951 376-98-787",
      email: "Ivanov_ivan@mail.ru",
      showMenu: false,
      locationSearch: "",
      navigation: [
        {
          name: "Главная",
          anchor: "home",
          active: true,
        },
        {
          name: "Механика",
          anchor: "steps",
          gtm: true,
          gtmText: "part",
        },
        {
          name: "Призы",
          anchor: "prizes",
          gtm: true,
          gtmText: "prizes",
        },
        {
          name: "Победители",
          anchor: "winners",
          gtm: true,
          gtmText: "winners",
        },
        {
          name: "FAQ",
          anchor: "faq",
          gtm: true,
          gtmText: "faq",
        },
      ],
    };
  },
  computed: {
    user() {
      console.log(this.$store.getters.user);
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    logout() {
      this.$store.dispatch("LogOut");
      document.location.reload();
    },
    showBurger() {
      this.showMenu = !this.showMenu;
    },
    toFeedback() {
      this.$modal.show("feedback");
    },
    wordVariant(items) {
      return items === 1 ? "сила" : items > 1 && 4 > items ? "силы" : "сил";
    },
  },
  mounted() {
    if (window.location.search.indexOf("utm_source") > -1) {
      this.locationSearch = window.location.search;
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  background: #020c04;
  border-bottom: 1px solid $grey;
  z-index: 1000;
  transition: 0.3s;
  &._modified {
  }
  .container {
    position: relative;
    padding-top: 0;
    padding-bottom: rem(0px);
    .header--mob_active & {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &.header--mob_active {
    .header__hamburger {
      opacity: 0;
      visibility: hidden;
    }

    .header__menu_mob {
      display: flex;
    }
    .header__menu_mob-close {
      opacity: 1;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 10;
  }
  &__nav {
    position: relative;
    display: none;
    width: 100%;
    align-items: center;
    flex-direction: column;
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: rem(24px);
      &-item {
        display: flex;
        align-items: center;
        font-size: rem(14px);
        font-family: $bold;
        text-transform: uppercase;
        &:not(:last-of-type) {
          margin-bottom: rem(13px);
        }
        svg,
        img {
          width: rem(16px);
          margin-right: rem(8px);
        }
      }
    }
  }

  &__nav-link {
    margin-bottom: 0;
    font-size: rem(20px);
    line-height: normal;
    text-align: center;
    font-weight: 300;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  &__user-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__logout {
    display: flex;
    align-items: center;
    font-family: $medium;
    margin-top: rem(16px);
    cursor: pointer;
    svg {
      display: block;
      width: rem(15px);
      margin-left: rem(10px);
      margin-top: rem(-5px);
    }
  }
  &__lk {
    height: rem(30px);
    margin-left: auto;
    & + .header__hamburger {
      margin: 0 0 0 rem(10px);
    }
    & + .header__menu_mob-close {
      margin: 0 0 0 rem(10px);
    }
  }
  &__hamburger {
    display: block;
    width: rem(29px);
    margin: 0 0 0 auto;
    img {
      width: 100%;
    }
  }
  &__menu {
    display: flex;
    position: fixed;
    width: 100vw;
    top: 0px;
    right: 0;
    height: 100vh;
    padding-top: vh(150px);
    padding-bottom: rem(20px);
    transform: translateY(-100%);
    transition: 0.5s;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #020c04;
    opacity: 0.5;
    z-index: 1;
    &._active {
      transform: translateY(0%);
      opacity: 1;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: vh(107px);
    }
    &-link {
      font-family: $light;
      text-align: center;
      font-size: rem(18px);
      margin-bottom: vh(15px);
    }
    &-bottom {
      display: flex;
    }
    .btn {
      min-width: auto;
    }
    &-img {
      position: absolute;
      object-fit: contain;
    }
  }
  &__logo {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: flex-start;
    margin-bottom: rem(-15px);
    margin-right: rem(10px);
    &-img {
      height: rem(55px);
    }
  }
  &__red {
    margin-right: rem(10px);
    // cursor: pointer;
    &-logo {
      height: rem(15px);
    }
  }

  &__5ka {
    // cursor: pointer;
    &-logo {
      height: rem(20px);
    }
  }
  &__login {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-transform: uppercase;
    transition: 0.4s;
    z-index: 2;
    cursor: pointer;
    &-icon {
      height: rem(30px);
      object-fit: contain;
      transition: 0.4s;
      &:last-of-type {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
      &-wrapper {
        position: relative;
        margin-right: rem(10px);
      }
    }
    &:hover {
      .header__login-icon:last-of-type {
        opacity: 1;
      }
    }
  }
  &__menu_mob-close {
    cursor: pointer;
    width: rem(29px);
    height: rem(24px);
    flex-shrink: 0;
    margin: 0 0 0 auto;
    background: url("../assets/images/icons/close-white.svg") no-repeat center;
    background-size: 100%;
    z-index: 10;
  }
  @media (orientation: landscape) {
    &__logo {
      margin-bottom: rem(-15px);
      margin-right: rem(50px);
      &-img {
        height: rem(155px);
      }
    }
    &__red {
      margin-right: rem(50px);
      // cursor: pointer;
      &-logo {
        height: rem(60px);
      }
    }

    &__5ka {
      // cursor: pointer;
      &-logo {
        height: rem(85px);
      }
    }
    &__hamburger {
      top: rem(53px);
      width: rem(59px);
    }
    &__menu {
      &-link {
        font-size: rem(50px);
      }
    }
    &__login {
      &-icon {
        height: rem(70px);
      }
    }
    &__menu_mob-close {
      top: rem(38px);
      right: rem(0px);
      width: rem(64px);
      height: rem(64px);
    }
  }
  @media (orientation: landscape) {
    .container {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
    }
    &__login {
      display: flex;
      align-items: center;
      height: rem(64px);
      padding: 0 rem(20px);
      margin: auto 0 auto auto;
      font-size: rem(18px);
      line-height: 1;
      text-transform: none;
      &-icon {
        height: rem(34px);
      }
    }
    &__row {
      align-items: flex-start;
    }
    &__menu-logos {
      display: none;
    }
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      height: rem(88px);
      margin: 0;
      transition: 0.4s;

      &-img {
        height: 100%;
      }
    }
    &__red {
      margin: 0 rem(23px);
      height: rem(30px);
      &-logo {
        height: 100%;
      }
    }
    &__5ka {
      height: rem(41px);
      &-logo {
        height: 100%;
      }
    }
    &__inner {
      padding: rem(7px) 0;
    }
    &__user-nav {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &__logout {
      margin-top: 0;
      margin-left: rem(46px);
      svg {
        width: rem(31px);
        margin-left: 0;
      }
    }
    &__nav {
      display: flex;
      align-items: center;
      flex-direction: row;
      &_mob {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-list {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        margin: auto;
        transition: 0.4s;
      }
      &-link {
        position: relative;
        padding: rem(16px) rem(23px);
        font-size: rem(18px);
        margin: 0;
        line-height: 1;
        text-align: center;
        border-radius: rem(8px);
        transition: all 0.3s ease-in-out;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        &.active,
        &.router-link-exact-active {
          background: rgba(181, 181, 181, 0.2);
          cursor: default;
          path {
            fill: $green_dark;
          }
        }
        svg {
          margin-left: rem(5px);
        }
      }
    }
    &__hamburger,
    &__menu_mob {
      display: none;
    }
  }
}

.header__menu_mob {
  display: none;
  input {
    background: none;
    border: none;
    outline: none;
    color: #fff;
    margin-right: rem(-30px);
    font-weight: 700;
    font-size: rem(14px);
    opacity: 1;
    &::placeholder {
      opacity: 1;
      color: #fff;
    }
  }
}

.header__menu_mob.active {
  display: flex !important;
}
</style>
