var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form__field",class:[
    _vm.color === 'white'
      ? 'form__field--white'
      : '' || _vm.color === 'green'
      ? 'form__field--green'
      : '',
    _vm.size ? 'form__field--' + _vm.size : '',
  ]},[(_vm.label)?_c('div',{staticClass:"form__caption",domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e(),(_vm.maskValue != '')?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.retValue),expression:"retValue",modifiers:{"trim":true}},{name:"mask",rawName:"v-mask",value:(_vm.maskValue),expression:"maskValue"}],staticClass:"form__field-input",class:[
      _vm.size ? 'form__field-input--' + _vm.size : '',
      _vm.placeholder ? '_has-placeholder' : '',
    ],attrs:{"disabled":_vm.isDisabled,"type":_vm.fieldType,"id":_vm.fieldId,"readonly":_vm.readonly,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"autocompletetype":_vm.autocompletetype,"placeholder":_vm.placeholder || ' ',"name":_vm.name},domProps:{"value":_vm.value,"value":(_vm.retValue)},on:{"focus":function($event){return _vm.errorReset()},"input":[function($event){if($event.target.composing)return;_vm.retValue=$event.target.value.trim()},function($event){return _vm.onInput()}],"blur":[function($event){return _vm.onBlur()},function($event){return _vm.$forceUpdate()}]}}):_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.retValue),expression:"retValue",modifiers:{"trim":true}}],staticClass:"form__field-input",class:_vm.size ? 'form__field-input--' + _vm.size : '',attrs:{"disabled":_vm.isDisabled,"type":_vm.fieldType,"id":_vm.fieldId,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete,"autocompletetype":_vm.autocompletetype,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder || ' ',"name":_vm.name},domProps:{"value":_vm.value,"value":(_vm.retValue)},on:{"focus":function($event){return _vm.errorReset()},"input":[function($event){if($event.target.composing)return;_vm.retValue=$event.target.value.trim()},function($event){return _vm.onInput()}],"blur":[function($event){return _vm.onBlur()},function($event){return _vm.$forceUpdate()}]}}),(_vm.type === 'password')?_c('div',{staticClass:"form__toggle",on:{"click":_vm.toggleType}},[(_vm.fieldType === 'password')?_c('img',{attrs:{"src":require("../../assets/images/icons/eye.svg"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/icons/eye-off.svg"),"alt":""}})]):_vm._e(),(_vm.error)?_c('div',{staticClass:"error-hint"},[_vm._v(_vm._s(_vm._f("typograf")(_vm.error)))]):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }