<template>
  <div class="coming-soon">
    <img
      src="../assets/images/coming-soon.png"
      alt=""
      class="coming-soon__img"
    />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$parent.showHeader = false;
    this.$parent.showFooter = false;
  },
};
</script>

<style lang="scss">
.coming-soon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../assets/images/coming-soon-bg.png") no-repeat center top;
  background-size: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    display: block;
    background: rgba($green, 0.85);
    backdrop-filter: blur(6px);
    z-index: 0;
  }
  &__img {
    position: relative;
    width: rem(670px);
    max-width: 90%;
    pointer-events: none;
    z-index: 1;
  }
}
</style>
