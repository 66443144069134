<template>
  <div class="feedback">
    <form class="feedback__form">
      <inputText
        label="Фио"
        placeholder="Введите имя"
        :field="delivery_fio"
        @input="delivery_fio = $event"
        :class="{
          error: validationStatus($v.delivery_fio) || errorMessage.delivery_fio,
        }"
        :error="
          (!$v.delivery_fio.required && $v.delivery_fio.$error
            ? 'Обязательное поле'
            : '') || errorMessage.delivery_fio
        "
      />
      <inputText
        label="Номер телефона"
        placeholder="+7 (xxx)xxx-xx-xx"
        :field="delivery_phone"
        :class="{
          error:
            validationStatus($v.delivery_phone) || errorMessage.delivery_phone,
        }"
        :error="
          (!$v.delivery_phone.required && $v.delivery_phone.$error
            ? 'Обязательное поле'
            : '') || errorMessage.delivery_phone
        "
        @input="delivery_phone = $event"
        type="tel"
        :mask="'+7(###) ###-##-##'"
      />
      <inputText
        label="Город"
        placeholder="Введите город"
        :field="delivery_city"
        @input="delivery_city = $event"
        :class="{
          error:
            validationStatus($v.delivery_city) || errorMessage.delivery_city,
        }"
        :error="
          (!$v.delivery_city.required && $v.delivery_city.$error
            ? 'Обязательное поле'
            : '') || errorMessage.delivery_city
        "
      />
      <inputText
        label="Регион"
        placeholder="Введите регион"
        :field="delivery_region"
        @input="delivery_region = $event"
        :class="{
          error:
            validationStatus($v.delivery_region) ||
            errorMessage.delivery_region,
        }"
        :error="
          (!$v.delivery_region.required && $v.delivery_region.$error
            ? 'Обязательное поле'
            : '') || errorMessage.delivery_region
        "
      />
      <inputText
        label="Улица"
        placeholder="Введите название улицы"
        :field="delivery_street"
        @input="delivery_street = $event"
        :class="{
          error:
            validationStatus($v.delivery_street) ||
            errorMessage.delivery_street,
        }"
        :error="
          (!$v.delivery_street.required && $v.delivery_street.$error
            ? 'Обязательное поле'
            : '') || errorMessage.delivery_street
        "
      />
      <inputText
        label="Дом"
        placeholder="Введите номер дома"
        :field="delivery_house"
        @input="delivery_house = $event"
        :class="{
          error:
            validationStatus($v.delivery_house) || errorMessage.delivery_house,
        }"
        :error="
          (!$v.delivery_house.required && $v.delivery_house.$error
            ? 'Обязательное поле'
            : '') || errorMessage.delivery_house
        "
      />
      <inputText
        label="Строение"
        placeholder="Номер строения"
        :field="building"
        @input="building = $event"
        :class="{
          error: validationStatus($v.building) || errorMessage.building,
        }"
        :error="errorMessage.building"
      />
      <inputText
        label="Корпус"
        placeholder="Номер корпуса"
        :field="building2"
        @input="building2 = $event"
        :class="{
          error: validationStatus($v.building2) || errorMessage.building2,
        }"
        :error="errorMessage.building2"
      />
      <inputText
        label="Квартира"
        placeholder="Введите номер квартиры"
        :field="delivery_flat"
        @input="delivery_flat = $event"
        :class="{
          error:
            validationStatus($v.delivery_flat) || errorMessage.delivery_flat,
        }"
        :error="errorMessage.delivery_flat"
      />
      <inputText
        label="Индекс"
        placeholder="XXXXXX"
        :field="zipcode"
        @input="zipcode = $event"
        :class="{
          error: validationStatus($v.zipcode) || errorMessage.zipcode,
        }"
        :error="
          (!$v.zipcode.required && $v.zipcode.$error
            ? 'Обязательное поле'
            : '') || errorMessage.zipcode
        "
      />
      <div class="modal__note">
        Персональные данные, отправленные через эту форму, будут использованы
        только для ответа на&nbsp;конкретный вопрос, а&nbsp;также в&nbsp;целях
        аналитики, и&nbsp;не&nbsp;будут использоваться в маркетинговых целях.
      </div>
      <checkBox
        label="Мне исполнилось 18 лет<sup class='_red'>*</sup>"
        :field="checkbox"
        class="_white"
        :class="{
          error: validationStatus($v.checkbox) || errorMessage.checkbox,
        }"
        :error="errorMessage.checkbox"
        @input="checkbox = $event"
      />
      <div class="modal__footer">
        <button
          type="button"
          class="btn btn--primary w-100"
          @click="submit()"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          <span>Отправить</span>
          <img
            src="../../assets/images/icons/spinner.svg"
            class="loader"
            alt=""
          />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";

import { required, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "DeliveryForm",
  data: () => ({
    submitStatus: null,
    delivery_fio: null,
    delivery_phone: null,
    zipcode: null,
    delivery_city: null,
    delivery_region: null,
    delivery_street: null,
    delivery_house: null,
    delivery_flat: null,
    building: null,
    building2: null,
    errorMessage: {
      delivery_fio: null,
      delivery_phone: null,
      zipcode: null,
      delivery_city: null,
      delivery_region: null,
      delivery_street: null,
      delivery_house: null,
      delivery_flat: null,
      building: null,
      building2: null,
      checkbox: null,
    },
    checkbox: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    // delivery: {
    //   get() {
    //     return this.$store.getters.user.delivery;
    //   },
    //   set(newVal) {
    //     return newVal;
    //   },
    // },
  },
  validations: {
    delivery_fio: { required },
    delivery_phone: { required },
    zipcode: { required },
    delivery_city: { required },
    delivery_region: { required },
    delivery_street: { required },
    delivery_house: { required },
    checkbox: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        delivery_fio: null,
        delivery_phone: null,
        zipcode: null,
        delivery_city: null,
        delivery_region: null,
        delivery_street: null,
        delivery_house: null,
        delivery_flat: null,
        building: null,
        building2: null,
        checkbox: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("UpdateAddress", {
            delivery_fio: this.delivery_fio,
            delivery_phone: this.delivery_phone,
            zipcode: this.zipcode,
            delivery_city: this.delivery_city,
            delivery_region: this.delivery_region,
            delivery_street: this.delivery_street,
            delivery_house: `${this.delivery_house}/${this.building}/${this.building2}`,
            delivery_flat: this.delivery_flat,
          })
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.delivery_phone) {
                [this.errorMessage.delivery_phone] = r.error.delivery_phone;
                fieldsError = true;
              }

              if (r.error && r.error.delivery_fio) {
                [this.errorMessage.delivery_fio] = r.error.delivery_fio;
                fieldsError = true;
              }
              if (r.error && r.error.zipcode) {
                [this.errorMessage.zipcode] = r.error.zipcode;
                fieldsError = true;
              }
              if (r.error && r.error.delivery_city) {
                [this.errorMessage.delivery_city] = r.error.delivery_city;
                fieldsError = true;
              }
              if (r.error && r.error.delivery_region) {
                [this.errorMessage.delivery_region] = r.error.delivery_region;
                fieldsError = true;
              }
              if (r.error && r.error.delivery_street) {
                [this.errorMessage.delivery_street] = r.error.delivery_street;
                fieldsError = true;
              }
              if (r.error && r.error.delivery_house) {
                [this.errorMessage.delivery_house] = r.error.delivery_house;
                fieldsError = true;
              }
              if (r.error && r.error.delivery_flat) {
                [this.errorMessage.delivery_flat] = r.error.delivery_flat;
                fieldsError = true;
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.error,
                });
              }
            } else {
              this.submitStatus = null;
              this.$modal.hide("delivery");
              this.$modal.show("common_success", {
                title: "БЛАГОДАРИМ",
                text: "Ваши данные успешно приняты в&nbsp;работу и&nbsp;будут переданы в&nbsp;службу доставки. Курьер заранее свяжется по&nbsp;указанному в&nbsp;форме номеру телефона.<br><br>Вручение призов осуществляется до&nbsp;15&nbsp;ОКТЯБРЯ 2024&nbsp;г.",
              });
              this.getUserData();
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  mounted() {},
  components: {
    inputText,
    checkBox,
  },
};
</script>

<style lang="scss">
.feedback {
  .form-checkbox__caption {
    margin-left: rem(18px);
  }
  &__text {
    margin-bottom: rem(8px);
    font-size: rem(18px);
    line-height: rem(18px);
    text-transform: none;
    color: #707070;
    font-family: "Reg";
  }
  &__checkbox_list {
    margin-bottom: rem(30px);
  }
  @media (orientation: landscape) {
    &__text {
      font-size: rem(26px);
    }
  }
  @media (orientation: landscape) {
    &__text {
      font-size: rem(15px);
    }
  }
}
</style>
