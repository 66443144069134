import { render, staticRenderFns } from "./AppFaq.vue?vue&type=template&id=0cd29ac3&scoped=true&"
import script from "./AppFaq.vue?vue&type=script&lang=js&"
export * from "./AppFaq.vue?vue&type=script&lang=js&"
import style0 from "./AppFaq.vue?vue&type=style&index=0&id=0cd29ac3&prod&lang=scss&scoped=true&"
import style1 from "./AppFaq.vue?vue&type=style&index=1&id=0cd29ac3&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd29ac3",
  null
  
)

export default component.exports