var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"signup email_form"},[_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.name) || _vm.errorMessage.name,
    },attrs:{"size":"md","color":"grey","placeholder":"Фамилия, имя, отчество *","type":"text","fieldId":"name","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
        ? 'Обязательное поле'
        : !_vm.$v.name.rus && _vm.$v.name.$error
        ? 'Введите текст на русском языке'
        : '') || this.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('div',{staticClass:"signup__checkbox_list"},[_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.checkbox) || _vm.errorMessage.checkbox,
      },attrs:{"label":`Я согласен (-на) с <a href='${_vm.publicPath}docs/rules.pdf' class='_undeline-link' target='_blank'>правилами акции</a> и <a href='${_vm.publicPath}docs/rules.pdf' class='_undeline-link' target='_blank'>пользовательским соглашением</a>`,"field":_vm.checkbox,"error":_vm.errorMessage.checkbox},on:{"input":function($event){(_vm.checkbox = $event), _vm.showRules()}}}),_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.checkbox2) || _vm.errorMessage.checkbox2,
      },attrs:{"label":"Я&nbsp;согласен на&nbsp;получение рекламных материалов","field":_vm.checkbox2,"error":_vm.errorMessage.checkbox2},on:{"input":function($event){_vm.checkbox2 = $event}}})],1),_c('div',{staticClass:"signup__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Зарегистрироваться "),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }